import type { JourneyStep } from 'types';

interface StageOverrides {
  backStage?: JourneyStep;
  forwardStage?: JourneyStep;
}

export function getStageOverrides(currentStage: JourneyStep): StageOverrides {
  switch (currentStage) {
    case 'DISCOVER':
      return { backStage: undefined, forwardStage: undefined };
    case 'LEARN':
      return { backStage: 'DISCOVER', forwardStage: 'TRAIN' };
    case 'TRAIN':
      return { backStage: 'LEARN', forwardStage: 'WORK' };
    case 'WORK':
      return { backStage: 'DISCOVER' };
  }
}
