import type { Milestones } from '@greenworkx/gwx-api/dist/spec/schemas';
import type { PromptContent } from '@lib/milestones';
import { gwxUserPrompts } from '@lib/milestones';

export function getPromptContentForMilestones(
  milestones: Exclude<Milestones, undefined>,
): PromptContent[] | [] {
  const prompts = [];
  for (const milestone of milestones) {
    const promptContent = gwxUserPrompts.find((prompt) => prompt.relatedMilestone === milestone);
    if (promptContent) {
      prompts.push(promptContent);
    }
  }
  return prompts;
}
