import { H1 } from '@components/Headings';
import { IconButton } from '@components/IconButton';
import { ProgressBar } from '@components/ProgressBar';
import { pageTitles } from '@lib/tanstack';
import type { FileRoutesByPath } from '@tanstack/react-router';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { useLocation, useSearch } from '@tanstack/react-router';
import { getBackPath } from '@util/get_back_path';
import { useAtomValue, useSetAtom } from 'jotai';
import { router } from 'main';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { feedbackDialogAtom } from 'store';
import { isAlreadyWorkingAtom } from 'store';

const getPageTitle = (pathname: string) => {
  if (pathname in pageTitles) {
    return pageTitles[pathname as keyof FileRoutesByPath];
  }
  return 'Unknown';
};

export function Header() {
  const pathName = useLocation({
    select: (location) => location.pathname,
  });
  const navigate = useNavigate();
  /* eslint-disable camelcase */
  const { utm_campaign, utm_source } = getRouteApi('__root__').useSearch();
  /* eslint-enable camelcase */
  const { pathway } = useSearch({ strict: false });

  const showProgressBar = ['/discover', '/learn', '/train', '/work'].includes(pathName);

  const setFeedbackDialogIsOpen = useSetAtom(feedbackDialogAtom);
  const isAlreadyWorking = useAtomValue(isAlreadyWorkingAtom);

  function handleFeedbackButtonClick() {
    setFeedbackDialogIsOpen(true);
  }

  function handleBackButtonClick() {
    const backPath = getBackPath(pathName, isAlreadyWorking);
    // Remove the `pathway` search param unless the current page is Learn
    const pathwayParam = pathName === router.routesByPath['/learn'].id ? undefined : pathway;

    void navigate({
      to: backPath,
      /* eslint-disable camelcase */
      search: { utm_campaign, utm_source, pathway: pathwayParam },
      /* eslint-enable camelcase */
    });
  }

  return (
    <header>
      <div className='h-36 flex items-center bg-forest text-white'>
        <div className='w-full flex items-center justify-between mx-8 md:mx-16 lg:mx-32'>
          <div className='flex gap-4 items-center'>
            <IconButton
              aria-label='Navigate to previous screen'
              onClick={handleBackButtonClick}
              icon={<IoChevronBackCircleOutline />}
              colour='white'
              variant='ghost'
              iconSize='1.5em'
            />
            <H1>{getPageTitle(pathName)}</H1>
          </div>
          <IconButton
            aria-label='Open feedback dialog'
            onClick={handleFeedbackButtonClick}
            icon={<AiOutlineQuestionCircle />}
            colour='white'
            variant='ghost'
            iconSize='1.5em'
          />
        </div>
      </div>
      {showProgressBar && (
        <div className='-mt-1 sm:-mt-2 max-w-screen-lg mx-auto px-8'>
          <ProgressBar />
        </div>
      )}
    </header>
  );
}
