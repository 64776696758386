// this component will contain CTAs for user actions that sit outside pathway specific content
// for example, signing up as a user, or completing profile

import { Button } from '@components/Button';
import { Skeleton } from '@components/Skeleton';
import { useLearnerApi } from '@lib/swr/useLearnerApi';
import { isExternalLink } from 'lib/link';
import type { GenericUserAction } from 'lib/recommend';
import type { ReactNode } from 'react';

interface Props {
  action: GenericUserAction;
  children?: ReactNode;
}

export function GenericAction({ action, children }: Props) {
  const { heading, buttonTitle, href } = action;

  const { gwxApiIsLoading } = useLearnerApi();

  return (
    <div className='w-full p-5 bg-white rounded-xl min-h-[200px] flex flex-col justify-between gap-4'>
      {gwxApiIsLoading ? (
        <Skeleton variant='paragraph' />
      ) : (
        <>
          <div className='text-forest'>
            <div>Suggested action</div>
            <div className='text-xl font-gtflexa-medium'>{heading}</div>
          </div>
          <div className='flex justify-center p-2'>{children}</div>
          <Button href={isExternalLink(href) ? href : undefined}>{buttonTitle}</Button>
        </>
      )}
    </div>
  );
}
