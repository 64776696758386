import { Button } from '@components/Button';
import { H1 } from '@components/Headings';
import { GwxLogoTextWhite } from '@components/Icons/GwxLogoTextWhite';
import { GwxSolarSticker } from '@components/Icons/GwxSolarSticker';
import { LinkExternal } from '@components/Links/LinkExternal';
import { LinkInternal } from '@components/Links/LinkInternal';
import { Skeleton } from '@components/Skeleton';
import { Spinner } from '@components/Spinner';
import { useContent } from '@lib/swr/useContent';
import { useLearnerApi } from '@lib/swr/useLearnerApi';
import { getRouteApi, useNavigate } from '@tanstack/react-router';

export function IndexPage() {
  const navigate = useNavigate();
  const globalSearchParams = getRouteApi('__root__').useSearch();

  function handleCtaButtonClick() {
    void navigate({
      to: '/discovery',
      search: { ...globalSearchParams },
    });
  }

  const { learner, gwxApiIsLoading } = useLearnerApi();
  const { content, contentApiIsLoading } = useContent();

  const { heading, description, cta } = content?.homePage ?? {};

  return (
    <main aria-busy={contentApiIsLoading} aria-hidden={contentApiIsLoading} aria-live='polite'>
      <div className='bg-black text-white'>
        <div className='min-h-dvh max-w-5xl p-8 flex flex-col mx-auto'>
          <div className='mb-16'>
            <GwxLogoTextWhite />
          </div>
          <div className='flex flex-col justify-between flex-1'>
            <div>
              {contentApiIsLoading && (
                <div className='md:w-3/4'>
                  <Skeleton variant='heading' />
                  <Skeleton variant='paragraph' />
                </div>
              )}
              {heading && (
                <div className='uppercase mb-4'>
                  <H1>{heading}</H1>
                </div>
              )}
              {description && <p className='text-xl'>{description}</p>}
            </div>

            <div className='ml-auto touch-auto'>
              <GwxSolarSticker />
            </div>

            <div className='items-center flex flex-col gap-4'>
              {contentApiIsLoading ? (
                <Skeleton variant='button' />
              ) : (
                <>
                  <Button onClick={handleCtaButtonClick} className='max-w-80 text-black'>
                    {cta?.label ?? 'Find the perfect pathway'}
                  </Button>
                </>
              )}
              <div aria-busy={gwxApiIsLoading} aria-hidden={gwxApiIsLoading} aria-live='polite'>
                {gwxApiIsLoading ? (
                  <Spinner />
                ) : learner ? (
                  <LinkInternal onClick={() => void navigate({ to: '/dashboard' })}>
                    Resume my journey
                  </LinkInternal>
                ) : (
                  <div>
                    Have an account?&nbsp;
                    <LinkExternal
                      href={`https://profile.greenworkx.org/login?redirectTo=${window.location.origin + '/dashboard'}`}
                    >
                      Log in
                    </LinkExternal>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
