import { h1Classes, h2Classes, h3Classes } from './styles';

interface Props {
  children: string;
}

export function H1({ children }: Props) {
  return <h1 className={h1Classes}>{children}</h1>;
}

export function H2({ children }: Props) {
  return <h2 className={h2Classes}>{children}</h2>;
}

export function H3({ children }: Props) {
  return <h3 className={h3Classes}>{children}</h3>;
}
