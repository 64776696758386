import { Button } from '@components/Button';
import { H2 } from '@components/Headings';
import { Skeleton } from '@components/Skeleton';
import { useContent } from '@lib/swr/useContent';
import { PortableText } from '@portabletext/react';
import { type ReactNode, useState } from 'react';

interface Props {
  handleSubmit: (answerKey: string) => void;
}

export function DiscoveryQuestion({ handleSubmit }: Props) {
  // TODO: handle error state
  const { content, contentApiIsLoading } = useContent();

  const questionContent = content?.questions.find(
    (question) => question.keyCustom === 'CAREER_STATUS',
  );

  const { question, instructions, answers, submitText } = questionContent ?? {};

  const [selectedAnswerKey, setSelectedAnswerKey] = useState<string | null>(null);

  const handleClick = (answerKey: string) => {
    if (selectedAnswerKey === answerKey) {
      setSelectedAnswerKey(null);
    } else {
      setSelectedAnswerKey(answerKey);
    }
  };

  return (
    <div
      className='flex flex-col gap-4'
      aria-busy={contentApiIsLoading}
      aria-hidden={contentApiIsLoading}
      aria-live='polite'
    >
      {contentApiIsLoading && <Skeleton variant='heading' />}
      {question && <H2>{question}</H2>}

      {contentApiIsLoading && <Skeleton variant='paragraph' />}
      {instructions && <PortableText value={instructions} />}

      <div className='flex flex-col md:flex-row gap-2'>
        {contentApiIsLoading &&
          [...Array<ReactNode>(2)].map((_, index) => (
            <div className='basis-full md:basis-1/2 h-96' key={index}>
              <Skeleton variant='paragraph' />
            </div>
          ))}
        {answers?.map((answer) => (
          <div className='basis-full md:basis-1/2 h-96' key={answer._key}>
            <Button
              shape='square'
              isActive={selectedAnswerKey === answer.keyCustom}
              onClick={() => {
                handleClick(answer.keyCustom);
              }}
              fontWeight='regular'
              textSize='lg'
              textCase='normal'
              isMenuOption={true}
            >
              <PortableText value={answer.answerText} />
            </Button>
          </div>
        ))}
      </div>

      <div className='flex justify-center self-center w-1/2'>
        {contentApiIsLoading ? (
          <Skeleton variant='button' />
        ) : (
          <Button
            onClick={() => {
              if (selectedAnswerKey === null) return;
              handleSubmit(selectedAnswerKey);
            }}
            isDisabled={selectedAnswerKey === null}
            role='link'
          >
            {submitText}
          </Button>
        )}
      </div>
    </div>
  );
}
