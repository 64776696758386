import type { Learner } from '@greenworkx/gwx-api/dist/spec/schemas';
import { createGwxApiClient } from '@lib/gwx_api';
import useSWR from 'swr';

export function useLearnerApi() {
  async function getLearner(): Promise<Learner> {
    const gwxApiClient = createGwxApiClient();
    return await gwxApiClient.getLearnerSelf();
  }

  const { data, isLoading, isValidating, error, mutate } = useSWR<Learner, Error>(
    'getLearner',
    getLearner,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  return {
    learner: data,
    gwxApiIsLoading: isLoading,
    gwxApiIsValidating: isValidating,
    gwxApiError: error,
    invalidateLearner: mutate,
  };
}
