import { Callout } from '@components/Callout';
import { RecommendationList } from '@components/RecommendationList/RecommendationList';
import { Skeleton } from '@components/Skeleton';
import { PATHWAY_KEYS_TO_CAMEL_CASE } from '@constants';
import { staggerFadeIn } from '@lib/framer-motion/stagger_fade_in';
import { isPathwaySearchParam } from '@lib/recommend';
import { useContent } from '@lib/swr/useContent';
import { useLearnerApi } from '@lib/swr/useLearnerApi';
import { PortableText } from '@portabletext/react';
import { createFileRoute, getRouteApi } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { getTypedObjectKeys } from '@util/get_typed_object_keys';
import { prepareStageOverrideComponents } from '@util/prepare_stage_override_text';
import { motion } from 'framer-motion';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { isAlreadyWorkingAtom, selectedPathwayAtom } from 'store';

export const Route = createFileRoute('/work')({
  component: WorkPage,
  validateSearch: (
    search: Record<string, unknown>,
  ): { pathway?: (typeof PATHWAY_KEYS_TO_CAMEL_CASE)[keyof typeof PATHWAY_KEYS_TO_CAMEL_CASE] } => {
    const { pathway } = search;
    if (isPathwaySearchParam(pathway)) {
      return { pathway };
    }
    return {};
  },
});

function WorkPage() {
  const navigate = useNavigate();

  const isAlreadyWorking = useAtomValue(isAlreadyWorkingAtom);

  if (!isAlreadyWorking) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw navigate({ to: '/' });
  }

  const { content, contentApiIsLoading } = useContent();
  const { learner, gwxApiIsLoading } = useLearnerApi();

  const [careerPathwayLocalStorage, setCareerPathwayLocalStorage] = useAtom(selectedPathwayAtom);

  const { pathway: pathwayParam } = Route.useSearch();

  /* eslint-disable camelcase */
  const { utm_campaign, utm_source } = getRouteApi('__root__').useSearch();
  /* eslint-enable camelcase */

  const pathwayKeyFromSearchParams = pathwayParam
    ? getTypedObjectKeys(PATHWAY_KEYS_TO_CAMEL_CASE).find(
        (key) => PATHWAY_KEYS_TO_CAMEL_CASE[key] === pathwayParam,
      )
    : undefined;
  if (pathwayKeyFromSearchParams) setCareerPathwayLocalStorage(pathwayKeyFromSearchParams);

  const careerPathway =
    pathwayKeyFromSearchParams ??
    learner?.careerPathway ??
    careerPathwayLocalStorage ??
    'ENERGY_ADVICE'; // Fallback for a new user who has told us they are already working but never selected a pathway.

  const pathwayContent = content?.careerPathways.find(
    (pathway) => pathway.keyCustom === careerPathway,
  );
  const helperMessage = pathwayContent?.workStage.helperMessage;
  const recommendations = pathwayContent?.workStage.recommendations;
  const stageOverrideBackValue = pathwayContent?.workStage.journeyBack;

  const handleRankLinkClick = useCallback(() => {
    void navigate({
      to: '/rank',
      /* eslint-disable camelcase */
      search: { utm_campaign, utm_source },
    });
  }, [utm_campaign, utm_source, navigate]);
  /* eslint-enable camelcase */

  /** Memoize the custom portable text object to reduce re-renders:
   * https://github.com/portabletext/react-portabletext?tab=readme-ov-file#customizing-components*/
  const stageOverrideBackComponents = useMemo(() => {
    return prepareStageOverrideComponents(() => {
      handleRankLinkClick();
    });
  }, [handleRankLinkClick]);

  return (
    <div
      className='py-6 flex flex-col gap-10 mt-4'
      aria-busy={contentApiIsLoading || gwxApiIsLoading}
      aria-hidden={contentApiIsLoading || gwxApiIsLoading}
      aria-live='polite'
    >
      {contentApiIsLoading ? (
        <Skeleton variant='paragraph' />
      ) : (
        helperMessage && (
          <Callout>
            <p>{helperMessage}</p>
          </Callout>
        )
      )}

      {contentApiIsLoading || gwxApiIsLoading ? (
        <>
          <Skeleton variant='paragraph' />
          <Skeleton variant='paragraph' />
          <Skeleton variant='paragraph' />
        </>
      ) : recommendations ? (
        <motion.div
          className='flex flex-col gap-4 md:gap-8'
          variants={staggerFadeIn}
          animate='animate'
          initial='initial'
        >
          <RecommendationList
            recommendations={[recommendations[0]]}
            title='Next step'
            hasTopPick={true}
            animation={staggerFadeIn}
          />
          {recommendations.length > 1 && (
            <RecommendationList
              recommendations={recommendations.slice(1)}
              title='Other steps'
              animation={staggerFadeIn}
            />
          )}
          <div>
            {stageOverrideBackValue && (
              <PortableText
                value={stageOverrideBackValue}
                components={stageOverrideBackComponents}
              />
            )}
          </div>
        </motion.div>
      ) : null}
    </div>
  );
}
