import type { RecommendationWithPathwayKey } from 'routes/discover';
import type { RecommendationContent } from 'types';

export const isDefined = <T>(arg: T | undefined): arg is T => arg !== undefined;

// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export function isPathway(
  recommendation: RecommendationWithPathwayKey | RecommendationContent,
): recommendation is RecommendationWithPathwayKey {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (recommendation as RecommendationWithPathwayKey).key !== undefined;
}
