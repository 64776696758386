import { H2 } from '@components/Headings';
import { GwxIcon } from '@components/Icons/GwxIcon';
import { useContent } from '@lib/swr/useContent';
import { motion, useAnimate, usePresence } from 'framer-motion';
import { useEffect, useState } from 'react';

export interface CalculatingResultsProps {
  onCalculationComplete: () => void;
  animationDuration: number; // Seconds
}

export function CalculatingResults({
  onCalculationComplete,
  animationDuration,
}: CalculatingResultsProps) {
  const { content } = useContent();
  const loadingMessages = content?.loading?.messages;

  const [loadingMessage, setLoadingMessage] = useState<string>(
    loadingMessages ? loadingMessages[0] : 'Calculating your results',
  );

  // Animation
  const [isPresent] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const animationPhases: {
      opacity?: number;
      width: `${string}%`;
      duration: number;
      label?: string;
    }[] = [
      {
        opacity: 1,
        width: '20%',
        duration: animationDuration * 0.2,
      },
      { width: '50%', duration: animationDuration * 0.3 },
      {
        width: '60%',
        duration: animationDuration * 0.2,
        label: loadingMessages ? loadingMessages[1] : 'Making a plan',
      },
      { width: '85%', duration: animationDuration * 0.2 },
      { width: '100%', duration: animationDuration * 0.1 },
    ];

    if (isPresent) {
      const enterAnimation = async () => {
        for (const { opacity, width, duration, label } of animationPhases) {
          if (label) {
            setLoadingMessage(label);
          }
          await animate('div', { opacity, width }, { duration });
        }
        onCalculationComplete();
      };
      void enterAnimation();
    }
  }, [animate, animationDuration, isPresent, onCalculationComplete, scope, loadingMessages]);

  return (
    <motion.div
      className='w-full h-full flex flex-col justify-center items-center gap-8 text-center'
      animate={{ opacity: [0, 1] }}
      transition={{ duration: 1 }}
    >
      <motion.svg
        className='w-1/4 min-w-48 aspect-square'
        animate={{ y: [0, 10, 0], opacity: [1, 0.9, 0.8, 0.9, 1] }}
        transition={{ repeat: Infinity, duration: 2 }}
      >
        <GwxIcon />
      </motion.svg>

      <motion.span
        key={loadingMessage}
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -30 }}
        transition={{ delay: 0.2 }}
      >
        {<H2>{loadingMessage}</H2>}
      </motion.span>

      <div className='h-6 w-full border-[3px] border-black rounded-full bg-slate-50' ref={scope}>
        <motion.div
          key='progress-bar'
          className='rounded-full bg-lime-500 h-full'
          initial={{ width: 0, opacity: 0 }}
        ></motion.div>
      </div>
    </motion.div>
  );
}
