import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { recommendPathwayFromRanking } from 'lib/recommend';
import type { JourneyStep, MotivatingFactorsKey, PathwayKey } from 'types';

export const isAlreadyWorkingAtom = atomWithStorage('already-working', false, undefined, {
  getOnInit: true,
});

export const motivatingFactorsAtom = atomWithStorage<MotivatingFactorsKey[]>('motivating-factors', [
  'CLIMATE',
  'CUSTOMER_FACING',
  'FUEL_POVERTY',
  'HANDS_ON',
  'HIGH_PAYING',
]);

export const careerRecommendations = atom((get) =>
  recommendPathwayFromRanking(get(motivatingFactorsAtom)),
);

export const selectedPathwayAtom = atomWithStorage<PathwayKey | null>(
  'pathway-key',
  null,
  undefined,
  { getOnInit: true },
);

export const journeyStageAtom = atomWithStorage<JourneyStep>(
  'journey-stage',
  'DISCOVER',
  undefined,
  {
    getOnInit: true,
  },
);

export const feedbackDialogAtom = atom<boolean>(false);

export const isMilestoneUserPromptVisibleAtom = atom<boolean>(false);
export const currentUserPromptIndexAtom = atom<number>(0);
export const isFinalPromptDismissedAtom = atom<boolean>(false);

export const dashboardWelcomeDismissed = atomWithStorage<boolean>(
  'dashboard-welcome-dismissed',
  false,
  undefined,
  { getOnInit: true },
);

export const gwxApiIsPatchingAtom = atom<boolean>(false);
