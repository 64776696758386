import type { ExternalLink } from '@lib/link';
import type { HTMLAttributeAnchorTarget } from 'react';

interface Props {
  href?: ExternalLink;
  target?: HTMLAttributeAnchorTarget;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  children: string;
}

export function NavigationListItem({ href, target, onClick, children }: Props) {
  const element = href ? (
    <a href={href} onClick={onClick} target={target}>
      {children}
    </a>
  ) : (
    <button onClick={onClick}>{children}</button>
  );

  return <li>{element}</li>;
}
