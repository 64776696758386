import { GwxIcon } from '@components/Icons/GwxIcon';
import { LinkExternal } from '@components/Links/LinkExternal';

export function Footer() {
  return (
    <footer className='relative bg-[#f8f8f8]'>
      <div className='bg-lime-300 pt-[16vw] md:pt-[8vw] p-12 w-full h-full flex flex-col justify-center md:items-end gap-8 md:gap-12 text-black text-sm md:text-lg clip-path-slant-top'>
        <p className='md:text-right md:w-2/3 max-w-screen-md'>
          This page is rated A on the{' '}
          <LinkExternal
            href='https://www.ecoindex.fr/en/result/?id=8d07ccf9-64b3-4cee-8fc9-605161823015'
            aria-label='Visit the ecoindex.fr website'
          >
            ecoindex.fr
          </LinkExternal>{' '}
          scale and emits 0.29<sub>gr</sub> of C0
          <sub>2</sub> based on{' '}
          <LinkExternal
            href='https://www.websitecarbon.com/'
            aria-label='Visit the Website Carbon Calculator website'
          >
            Website Carbon assessment
          </LinkExternal>{' '}
          (cleaner than 68% of pages globally as of September 2024)
        </p>
        <a
          className='w-12 md:w-16'
          href='https://greenworkx.org'
          target='_blank'
          aria-label='Visit the Greenworkx website'
        >
          <GwxIcon />
        </a>
      </div>
    </footer>
  );
}
