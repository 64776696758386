import classNames from 'classnames';
import type { ReactNode } from 'react';

import type { ButtonShape, ButtonTextCase, ButtonTextSize, ButtonWeight } from './types';

interface Props {
  children: ReactNode;
  shape?: ButtonShape;
  fontWeight?: ButtonWeight;
  textSize?: ButtonTextSize;
  textCase?: ButtonTextCase;
  onClick?: (() => void) | (() => Promise<void>);
  isDisabled?: boolean;
  isActive?: boolean;
  isMenuOption?: boolean;
  href?: string;
  role?: React.AriaRole;
  isContained?: boolean;
  className?: string;
}

export function Button({
  children,
  shape = 'pill',
  fontWeight = 'bold',
  textSize = 'md',
  textCase = 'uppercase',
  onClick,
  isDisabled = false,
  isActive = false,
  isMenuOption = false,
  href,
  role,
  isContained = false,
  className,
}: Props) {
  const classes = classNames(
    className,
    'focus-visible:outline-auto focus-visible:outline-offset-2',
    {
      'border-2 border-black text-center transition-colors hover:duration-150 focus-visible:duration-150 duration-500 motion-reduce:transition-none':
        shape !== 'text',
      'text-sm': textSize === 'sm',
      'text-base': textSize === 'md',
      'text-lg': textSize === 'lg',

      'font-gtflexa-regular': fontWeight === 'regular',
      'font-gtflexa-medium': fontWeight === 'medium',
      'font-gtflexa-bold': fontWeight === 'bold',

      'w-full px-4 py-2 rounded-full': shape === 'pill',
      'w-full p-8 rounded-lg': shape === 'square',

      uppercase: textCase === 'uppercase',

      'bg-lime-500 hover:bg-lime-200 focus-visible:bg-lime-200': !isDisabled && shape !== 'text',
      'bg-gray-300': isDisabled,

      // When button is a menu option
      'bg-white hover:bg-lime-500': isMenuOption && !isActive,

      underline: shape === 'text',
    },
  );

  // Render a basic `div` if already contained in a button or anchor
  if (isContained) {
    return <div className={classes}>{children}</div>;
  }

  return href ? (
    <a
      className={classes}
      href={href}
      onClick={() => onClick}
      aria-disabled={isDisabled}
      target='_blank'
    >
      {children}
    </a>
  ) : (
    <button
      className={classes}
      onClick={() => (onClick ? void onClick() : undefined)}
      disabled={isDisabled}
      role={role}
    >
      {children}
    </button>
  );
}
