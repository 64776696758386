import classNames from 'classnames';
import type { JourneyStepIndex } from 'types';

interface Props {
  activeStepIndex: JourneyStepIndex;
}

export function ProgressBarRail({ activeStepIndex }: Props) {
  const baseClasses = 'rounded-[20px] h-full absolute top-0 left-0';

  const backgroundLayerClasses = classNames(baseClasses, 'w-full bg-grey');

  const foregroundLayerClasses = classNames(
    baseClasses,
    'duration-[1500ms] transition-[width] motion-reduce:transition-none bg-black',
    {
      'w-0': activeStepIndex === 0,
      'w-[33%]': activeStepIndex === 1,
      'w-[66%]': activeStepIndex === 2,
      'w-full': activeStepIndex === 3,
    },
  );

  return (
    <div className='relative h-2 sm:h-4 mx-1'>
      <div className={backgroundLayerClasses}></div>
      <div className={foregroundLayerClasses}></div>
    </div>
  );
}
