/**
 * Object.keys in JavaScript types each of its `key` values as type `string`.
 * This means accessing the key in TypeScript will present type errors.
 * This wrapper is a helpful way to extract keys from an object while maintaining type safety.
 * A great 60 second video on the topic can be found at: https://www.youtube.com/watch?v=GW00zebIt0g
 */

export function getTypedObjectKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}
