export function GwxArrowRightIcon() {
  return (
    <svg
      width='45'
      height='45'
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden={true}
    >
      <circle cx='22.5' cy='22.5' r='22.5' fill='#121212' />
      <path
        d='M15 31L31 15M31 15H15M31 15V31'
        stroke='white'
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  );
}
