import { Card } from '@components/Cards/Card';
import { H2 } from '@components/Headings';
import { isPathway } from '@lib/helpers';
import classNames from 'classnames';
import { motion, type Variants } from 'framer-motion';
import { type Dispatch, type SetStateAction } from 'react';
import type { RecommendationWithPathwayKey } from 'routes/discover';
import type { PathwayKey, RecommendationContent } from 'types';

interface Props {
  recommendations: RecommendationWithPathwayKey[] | RecommendationContent[];
  title?: string;
  layout?: 'columns' | 'full';
  hasTopPick?: boolean;
  animation: Variants;
  selectedPathwayButton?: PathwayKey | null;
  setSelectedPathwayButton?: Dispatch<SetStateAction<PathwayKey | null>>;
  itemsAreSelectable?: boolean;
}

export function RecommendationList({
  recommendations,
  title,
  layout = 'full',
  hasTopPick = false,
  animation,
  selectedPathwayButton,
  setSelectedPathwayButton,
  itemsAreSelectable = false,
}: Props) {
  const listLayoutClasses = classNames('grid gap-6', {
    'md:grid-cols-1': layout === 'full',
    'md:grid-cols-2': layout === 'columns',
  });

  const cardLayoutClasses = classNames({
    'col-span-2': layout === 'full',
    'col-span-1': layout === 'columns',
  });

  return (
    <div className='flex flex-col gap-4 md:gap-8'>
      {title && (
        <motion.div variants={animation} className='ml-4'>
          <H2>{title}</H2>
        </motion.div>
      )}
      <div className={listLayoutClasses}>
        {recommendations.map((rec, index) => {
          const key = isPathway(rec) ? rec.key : rec._id;
          return (
            <div key={key}>
              <motion.div variants={animation} className={cardLayoutClasses}>
                <Card
                  recommendation={rec}
                  isTopPick={hasTopPick && index === 0}
                  isSelectable={itemsAreSelectable}
                  isSelected={isPathway(rec) && selectedPathwayButton === rec.key}
                  setSelectedPathwayButton={setSelectedPathwayButton}
                />
              </motion.div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
