import groq from 'groq';

// https://www.sanity.io/docs/query-cheat-sheet

const HOME_PAGE = groq`*[_id=="journeyHomePage" && _type=="journeyHomePage"][0] { 
  heading, 
  description, 
  cta { 
    label 
  } 
}`;

const QUESTIONS = groq`*[_type =="question"] { 
  keyCustom,
  question,
  instructions,
  answers[] {
    keyCustom,
    _key,
    answerText
  },
  submitText
}`;

const DISCOVER_PAGE = groq`*[_type == "journeyDiscoverPage" && _id=="journeyDiscoverPage"][0] {
  helperMessage
}`;

const LOADING = groq`*[_type == "journeyLoading" && _id=="journeyLoading"][0] {
  messages
}`;

const FEEDBACK = groq`*[_type == "journeyFeedback" && _id=="journeyFeedback"][0] {
  title,
  body,
  cta {
    label
  }
}`;

const DASHBOARD = groq`*[_type == "journeyDashboard" && _id=="journeyDashboard"][0] {
  welcomeMessage
}`;

const CAREER_PATHWAYS = groq`*[_type == "careerPathway"] {
  _id,
  keyCustom,
  title,
  description,
  responsibilities,
  timeline {
    steps[] {
      title,
      intervalToNextStep,
      isOptional,
      _key
    }
  },
  careers[]-> {
    _id,
    title,
    description,
    url,
    minSalary,
    maxSalary,
    keyCustom,
  },
  learnStage {
    recommendations[]-> {
      _id,
      title,
      description,
      url,
      isComingSoon,
      cta {
        label
      }
    },
    helperMessage,
    journeyBack,
    journeyForward
  },
  trainStage {
    recommendations[]-> {
      _id,
      title,
      description,
      url,
      isComingSoon,
      cta {
        label
      }
    },
    helperMessage,
    journeyBack,
    journeyForward
  },
  workStage {
    recommendations[]-> {
      _id,
      title,
      description,
      url,
      isComingSoon,
      cta {
        label
      }
    },
    helperMessage,
    journeyBack,
  },
}`;

export const ALL_CONTENT = groq`{ "homePage": ${HOME_PAGE}, "questions": ${QUESTIONS}, "discoverPage": ${DISCOVER_PAGE}, "careerPathways": ${CAREER_PATHWAYS}, "feedback": ${FEEDBACK}, "loading": ${LOADING}, "dashboard": ${DASHBOARD}}`;
