export const FEEDBACK_LOAD_DELAY_MS = 500;

interface Props {
  currentPromptIndex: number;
  setIsPromptVisible: (arg: boolean) => void;
  setCurrentUserPromptIndex: (arg: number) => void;
}

export function advanceToNextUserPrompt({
  currentPromptIndex,
  setIsPromptVisible,
  setCurrentUserPromptIndex,
}: Props) {
  setTimeout(() => {
    setCurrentUserPromptIndex(currentPromptIndex + 1);
    setIsPromptVisible(true);
  }, FEEDBACK_LOAD_DELAY_MS);

  return {
    currentPromptIndex,
    setIsPromptVisible,
  };
}
