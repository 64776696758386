import { H2 } from '@components/Headings';
import { IconButton } from '@components/IconButton';
import {
  Description,
  Dialog as HeadlessUIDialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import classNames from 'classnames';
import { type ReactNode } from 'react';
import { IoMdClose } from 'react-icons/io';

interface Props {
  title: string;
  description: string;
  variant?: 'celebration';
  imageLink?: string;
  altText?: string;
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
}

export function Dialog({
  title,
  description,
  variant,
  imageLink,
  altText,
  isOpen = false,
  onClose,
  children,
}: Props) {
  const layoutClasses = classNames(
    'relative mx-auto max-h-full max-w-full overflow-y-auto rounded bg-white p-6 md:max-w-xl flex flex-col gap-6',
    {
      'text-center items-center justify-center gap-8 py-12 px-8 md:px-24 rounded-xl':
        variant === 'celebration',
    },
  );

  return (
    <HeadlessUIDialog
      onClose={onClose}
      open={isOpen}
      className='transition-opacity duration-200 ease-out motion-reduce:transition-none data-[closed]:opacity-0'
      as='div'
      title={title}
      transition={true}
    >
      <DialogBackdrop className='fixed inset-0 bg-black/60' />
      {/* Full-screen container to center the panel */}
      <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
        <DialogPanel className={layoutClasses}>
          <div className='grow text-center pr-4 mt-4'>
            <DialogTitle as={H2}>{title}</DialogTitle>
          </div>
          <div className='absolute right-4 top-4'>
            <IconButton
              onClick={onClose}
              aria-label='Close dialog'
              variant='ghost'
              icon={<IoMdClose />}
              data-autofocus
            />
          </div>

          <Description className='whitespace-pre-line'>{description}</Description>
          {imageLink && <img src={imageLink} alt={altText} className='w-32 md:w-44' />}
          {children}
        </DialogPanel>
      </div>
    </HeadlessUIDialog>
  );
}
