import classNames from 'classnames';
import type { HTMLAttributes } from 'react';
import { IconContext } from 'react-icons';
export interface IconButtonProps extends HTMLAttributes<HTMLButtonElement> {
  icon: React.ReactNode;
  onClick: () => void;
  variant?: 'outlined' | 'ghost';
  colour?: 'white' | 'black';
  iconSize?: '1em' | '1.5em';
}

export function IconButton({
  icon,
  onClick,
  variant = 'outlined',
  colour = 'black',
  iconSize = '1em',
  ...props
}: IconButtonProps) {
  const buttonClasses = classNames(
    'grid items-center aspect-square rounded-full p-2 [@media(hover:hover)]:hover:duration-150 duration-500 transition-color focus-visible:duration-150',
    {
      '[@media(hover:hover)]:hover:bg-slate-100 focus-visible:bg-slate-100': variant === 'ghost',
      'border-2': variant === 'outlined',
      'border-black text-black': colour === 'black',
      'border-white text-white': colour === 'white',
      '[@media(hover:hover)]:hover:text-black focus-visible:text-black':
        colour === 'white' && variant === 'ghost',
    },
    props.className,
  );

  return (
    <IconContext.Provider value={{ size: iconSize }}>
      <button {...props} onClick={onClick} className={buttonClasses}>
        <span aria-hidden={true}>{icon}</span>
      </button>
    </IconContext.Provider>
  );
}
