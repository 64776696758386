import classNames from 'classnames';

interface Props {
  number: number;
  label: string;
  isActive: boolean;
}

export function ProgressBarStep({ number, label, isActive }: Props) {
  const commonTransitionClasses = classNames(
    'ease-[cubic-bezier(0.25,1,0.5,1)] duration-[1500ms]',
    {
      'delay-[1500ms]': isActive,
    },
  );
  const circleTransitionClasses = 'transition-colors motion-reduce:transition-none ';
  const labelTransitionClasses = 'transition-[text-decoration] motion-reduce:transition-none';

  const baseClasses = classNames('sm:mb-3 relative text-xs md:text-xl lg:text-2xl flex flex-col', {
    'items-start': number === 1,
    'items-center': number === 2 || number === 3,
    'items-end': number === 4,
  });

  const circleClasses = classNames(
    'rounded-full border-2 sm:border-[3px] border-black bg-black w-6 h-6 sm:w-11 sm:h-11 sm:mt-0 grid place-items-center',
    commonTransitionClasses,
    circleTransitionClasses,
    {
      'bg-lime-500': isActive,
      'bg-black': !isActive,
    },
  );

  const numberTextClasses = classNames(commonTransitionClasses, circleTransitionClasses, {
    'text-black': isActive,
    'text-white': !isActive,
  });

  const labelClasses = classNames(
    'absolute top-8 sm:top-[3.5rem]',
    commonTransitionClasses,
    labelTransitionClasses,
    {
      'decoration-transparent': !isActive,
      'decoration-black underline decoration-[3px] underline-offset-[12px]': isActive,
    },
  );

  const labelUpperCase = label.toUpperCase();

  return (
    <div className={baseClasses}>
      <div className={circleClasses}>
        <span className={numberTextClasses}>{String(number)}</span>
      </div>
      <div className={labelClasses}>
        <div>{labelUpperCase}</div>
      </div>
    </div>
  );
}
