interface Props {
  children: string;
  isListItem?: boolean;
}

export function Pill({ children, isListItem = false }: Props) {
  const classes = 'bg-forest text-white rounded-full p-2 text-sm w-fit inline-block';

  const Tag = isListItem ? 'li' : 'div';

  return <Tag className={classes}>{children}</Tag>;
}
