import { Button } from '@components/Button';
import { Skeleton } from '@components/Skeleton';
import type { InternalLink } from '@lib/link';
import { useLearnerApi } from '@lib/swr/useLearnerApi';
import { useNavigate } from '@tanstack/react-router';
import type { RecommendationContent } from 'types';

interface Props {
  recommendation: RecommendationContent & { internalLink?: InternalLink };
}

export function NextAction({ recommendation }: Props) {
  const navigate = useNavigate();
  const { title, url, cta, internalLink } = recommendation;

  const { gwxApiIsLoading } = useLearnerApi();

  function handleClick() {
    void navigate({
      to: internalLink,
    });
  }

  return (
    <div className='w-full p-5 bg-forest rounded-xl min-h-[200px] flex flex-col justify-between gap-4'>
      {gwxApiIsLoading ? (
        <Skeleton variant='paragraph' />
      ) : (
        <>
          <div className='text-white'>
            <div>Your recommended next step</div>
            <div className='text-xl font-gtflexa-medium'>{title}</div>
          </div>
          <Button
            href={!internalLink ? url : undefined}
            onClick={internalLink ? handleClick : undefined}
          >
            {cta.label}
          </Button>
        </>
      )}
    </div>
  );
}
