import { ROUTE_PATHS_IN_NAVIGATION_ORDER } from '@constants';
import type { FileRoutesByPath } from '@tanstack/react-router';

/* This utility is primarily used by the back button in the Header component.
 * Rather than simply navigating to the previous page in browser history,
 * we want to direct users back to the previous screen in logical sequence,
 * as defined by `ROUTE_PATHS_IN_NAVIGATION_ORDER`.
 */
export function getBackPath(
  currentPath: string,
  isAlreadyWorking: boolean,
): keyof FileRoutesByPath {
  let backPath: keyof FileRoutesByPath = '/';

  /* If a user selects "already working" in the `/discovery` question,
   * they effectively jump forwards in the route paths and skip numerous steps.
   * To handle this, we direct them back to the question screen.
   * Else, we find the previous step in the sequence.
   */
  if (['/discover', '/work'].includes(currentPath) && isAlreadyWorking) {
    backPath = '/discovery';
  } else {
    for (let i = 0; i < ROUTE_PATHS_IN_NAVIGATION_ORDER.length; i++) {
      if (ROUTE_PATHS_IN_NAVIGATION_ORDER[i] === currentPath) {
        backPath = ROUTE_PATHS_IN_NAVIGATION_ORDER[i - 1];
      }
    }
  }

  return backPath;
}
