import { IconButton } from '@components/IconButton';
import { Skeleton } from '@components/Skeleton';
import { PortableText } from '@portabletext/react';
import classNames from 'classnames';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import type { RankedAnswerContent } from 'types';

export interface RankingCardProps {
  handleSwap: (index1: number, index2: number) => void;
  isFirst: boolean;
  isLast: boolean;
  index: number;
  isLoading: boolean;
  content: RankedAnswerContent;
}

export function RankingCard({
  handleSwap,
  isFirst,
  isLast,
  index,
  isLoading,
  content,
}: RankingCardProps) {
  const classes = classNames('h-28 flex items-center gap-2 p-4 text-base md:text-lg border-x-0', {
    'border-t-0': isFirst,
    'border-b-0': isLast,
    'border-[0.5px] border-b-grey': !isLast,
    'border-[0.5px] border-t-grey': !isFirst,
  });

  function getAccessibleLabel() {
    if (typeof content === 'string') return content;
    return content.map((block) => block.children?.map((child) => child.text).join('')).join('\n\n');
  }

  const accessibleLabel = content ? getAccessibleLabel() : undefined;

  return (
    <div className={classes}>
      {isLoading ? (
        <div className='flex flex-col grow'>
          <Skeleton variant='paragraph' />
        </div>
      ) : (
        <>
          <div className='grow flex'>
            <span className='font-gtflexa-medium mr-2'>{String(index + 1)}.</span>
            {typeof content === 'string' ? <div>{content}</div> : <PortableText value={content} />}
          </div>
          <div className='flex gap-3'>
            {!isFirst && (
              <div className='w-10'>
                <IconButton
                  onClick={() => {
                    handleSwap(index, index - 1);
                  }}
                  icon={<FaChevronUp />}
                  variant='ghost'
                  aria-label={`Rank higher: ${accessibleLabel ?? ''}`}
                />
              </div>
            )}

            <div className='w-10'>
              {!isLast && (
                <IconButton
                  onClick={() => {
                    handleSwap(index, index + 1);
                  }}
                  icon={<FaChevronDown />}
                  variant='ghost'
                  aria-label={`Rank lower: ${accessibleLabel ?? ''}`}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
