import { Button } from '@components/Button';
import { CalculatingResults } from '@components/CalculatingResults';
import { Error } from '@components/Error';
import { H2 } from '@components/Headings';
import { RankingCardList } from '@components/Ranking/RankingCardList';
import { Skeleton } from '@components/Skeleton';
import { TransitionFadeInOut } from '@components/Transitions/FadeInOut';
import { zMotivatingFactors } from '@greenworkx/gwx-api/dist/spec/schemas';
import { isDev } from '@lib/env';
import { createGwxApiClient } from '@lib/gwx_api';
import { useContent } from '@lib/swr/useContent';
import { PortableText } from '@portabletext/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useAtom } from 'jotai';
import { useLearnerApi } from 'lib/swr/useLearnerApi';
import { useEffect, useState } from 'react';
import { motivatingFactorsAtom } from 'store';
import type { MotivatingFactorsKey } from 'types';

export const Route = createFileRoute('/rank')({
  component: RankPage,
});

function RankPage() {
  const navigate = useNavigate();
  const searchParams = Route.useSearch();

  const [isRankingCardListVisible, setIsRankingCardListVisible] = useState(true);
  const [isCalculating, setIsCalculating] = useState(false);

  // TODO: handle error states
  const { learner, gwxApiIsLoading } = useLearnerApi();
  const { content, contentApiIsLoading, contentApiError } = useContent();

  const questionContent = content?.questions.find(
    (question) => question.keyCustom === 'MOTIVATING_FACTORS',
  );

  const [motivatingFactorsLocalStorage, setMotivatingFactorsLocalStorage] =
    useAtom(motivatingFactorsAtom);

  const [optionsRanked, setOptionsRanked] = useState<MotivatingFactorsKey[]>(
    learner?.motivatingFactors ?? motivatingFactorsLocalStorage,
  );

  useEffect(() => {
    if (learner?.motivatingFactors) setOptionsRanked(learner.motivatingFactors);
  }, [learner]);

  async function updateLearner() {
    const gwxApiClient = createGwxApiClient();
    try {
      await gwxApiClient.updateLearner(
        { motivatingFactors: optionsRanked },
        { params: { id: 'self' } },
      );
    } catch {
      return;
    }
  }

  async function handleSubmit() {
    setMotivatingFactorsLocalStorage(optionsRanked);
    setIsRankingCardListVisible(false);
    setIsCalculating(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (learner && optionsRanked !== learner.motivatingFactors) await updateLearner();
  }

  const answerKeys = questionContent?.answers.map((answer) => answer.keyCustom);
  if (answerKeys) {
    const answerKeysValidated = zMotivatingFactors.safeParse(answerKeys);

    if (!answerKeysValidated.success) {
      console.error(
        'Motivating factors keys from content API failed validation',
        answerKeysValidated.error,
      );
      return <Error />;
    }
  }

  if (contentApiError || (content && !questionContent)) return <Error />;

  return (
    <>
      <TransitionFadeInOut isVisible={isRankingCardListVisible} transitionKey='ranking'>
        <div
          aria-busy={contentApiIsLoading || gwxApiIsLoading}
          aria-hidden={contentApiIsLoading || gwxApiIsLoading}
          aria-live='polite'
        >
          {questionContent ? (
            <>
              <div className='mb-4'>
                <H2>{questionContent.question}</H2>
              </div>
              {questionContent.instructions && (
                <PortableText value={questionContent.instructions} />
              )}
            </>
          ) : (
            <>
              <Skeleton variant='heading' />
              <Skeleton variant='paragraph' />
            </>
          )}

          <div className='-mx-4'>
            <RankingCardList
              isLoading={gwxApiIsLoading || contentApiIsLoading}
              optionsRanked={optionsRanked}
              setOptionsRanked={setOptionsRanked}
              answersContent={questionContent?.answers}
            />
          </div>

          <div className='max-w-[600px] mx-auto'>
            {questionContent ? (
              <Button
                onClick={async () => {
                  await handleSubmit();
                }}
                role='link'
              >
                {questionContent.submitText}
              </Button>
            ) : (
              <Skeleton variant='button' />
            )}
          </div>
        </div>
      </TransitionFadeInOut>

      {isCalculating && (
        <div className='h-[60vh]'>
          <CalculatingResults
            animationDuration={isDev ? 1 : 3}
            onCalculationComplete={() => {
              void navigate({
                to: '/discover',
                search: { ...searchParams },
              });
            }}
          />
        </div>
      )}
    </>
  );
}
