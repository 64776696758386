import type { Learner } from '@greenworkx/gwx-api/dist/spec/schemas';
import type { PromptContent } from '@lib/milestones';
import { getUniqueElementDifference } from '@util/get_unique_element_difference';

import { getPromptContentForMilestones } from './get_prompt_content_for_milestones';

export function getUnseenPrompts(learner: Partial<Learner>): PromptContent[] | undefined {
  const completed = learner.milestones?.completed ?? [];
  const completedAndSeen = learner.milestones?.completedAndSeen ?? [];

  const unseenCompletedMilestones = getUniqueElementDifference(completed, completedAndSeen);

  return unseenCompletedMilestones.length > 0
    ? getPromptContentForMilestones(unseenCompletedMilestones)
    : undefined;
}
