import { Button } from '@components/Button';
import { Dialog } from '@components/Dialog';
import { Header } from '@components/Header';
import { TransitionFadeInOut } from '@components/Transitions/FadeInOut';
import { PATH_TO_JOURNEY_STEP } from '@constants';
import { useContent } from '@lib/swr/useContent';
import { Outlet, ScrollRestoration, useRouterState } from '@tanstack/react-router';
import { useAtom, useSetAtom } from 'jotai';
import { feedbackDialogAtom, journeyStageAtom } from 'store';
import { type JourneyPath } from 'types';

export function RootLayout() {
  const { location } = useRouterState();

  const setJourneyStage = useSetAtom(journeyStageAtom);
  const pathName: JourneyPath | undefined = Object.keys(PATH_TO_JOURNEY_STEP).includes(
    location.pathname,
  )
    ? (location.pathname as unknown as JourneyPath)
    : undefined;

  if (pathName !== undefined) {
    setJourneyStage(PATH_TO_JOURNEY_STEP[pathName]);
  }

  const [feedbackDialogIsOpen, setFeedbackDialogIsOpen] = useAtom(feedbackDialogAtom);

  const { content } = useContent();
  const dialogContent = content?.feedback;

  return location.pathname === '/' || location.pathname === '/dashboard' ? (
    <TransitionFadeInOut transitionKey={location.pathname}>
      <ScrollRestoration />
      <Outlet />
    </TransitionFadeInOut>
  ) : (
    <main>
      <Dialog
        title={dialogContent?.title ?? 'Feedback'}
        description={
          dialogContent?.body ??
          `We'd appreciate if you gave us some feedback on your experience using this new app.`
        }
        isOpen={feedbackDialogIsOpen}
        onClose={() => {
          setFeedbackDialogIsOpen(false);
        }}
      >
        <Button href='https://airtable.com/appoBnn8cRrRiCYaR/pagS4S3udyMl3bMyI/form?prefill_About=Journey+App'>
          {dialogContent?.cta.label ?? 'Submit feedback'}
        </Button>
      </Dialog>

      <div className='flex flex-col min-h-screen mb-12'>
        <div className='mb-12'>
          <Header />
        </div>
        <div className='flex justify-center'>
          <div className='max-w-screen-md md:px-0 px-4 grow'>
            <TransitionFadeInOut transitionKey={location.pathname}>
              <ScrollRestoration />
              <Outlet />
            </TransitionFadeInOut>
          </div>
        </div>
      </div>
    </main>
  );
}
