import { progressBaseClasses, progressOverlayClasses, progressRotationClasses } from './styles';

interface Props {
  completion: number;
}

// RadialProgressBar uses layered divs with rotation transformation styling applied to create
// a radial progress bar that visually represents a given inputted percentage

export function RadialProgressBar({ completion }: Props) {
  // rotateRight generates the rotation degree for completion percentages under 50%.
  // it takes a number with a max value of 50 and multiplies it by 3.6 to convert the number% to degrees.
  // 3.6 equates to 360 degrees / 100% = 3.6 degrees per percent
  const rotateRight = Math.min(completion, 50) * 3.6;

  // rotateLeft generates the rotation degree for completion percentages above 50%.
  // it subtracts 50 because the left half of the rotation begins at 50%
  // it adds 180 so that the left div continues from where the right div stopped
  // if the completion % is < 50 then the left div stacks with the right
  const rotateLeft = completion > 50 ? (completion - 50) * 3.6 + 180 : 0;

  const rotateRightStyle = { transform: `rotate(${rotateRight.toString()}deg)` };
  const rotateLeftStyle = { transform: `rotate(${rotateLeft.toString()}deg)` };

  return (
    <div className={progressBaseClasses}>
      <span className='z-20'>{completion}%</span>
      <div className={progressOverlayClasses}></div>
      <div className={progressRotationClasses} style={rotateRightStyle}></div>
      {completion > 50 && (
        <div className={`${progressRotationClasses} z-30`} style={rotateLeftStyle}></div>
      )}
    </div>
  );
}
