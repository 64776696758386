import { ProgressBar } from '@components/ProgressBar';
import { Spinner } from '@components/Spinner';
import { JOURNEY_STEPS_SEQUENCE, PATHWAY_KEYS } from '@constants';
import { useLearnerApi } from '@lib/swr/useLearnerApi';
import classNames from 'classnames';
import { capitalize, startCase, toLower } from 'lodash';
import { type JourneyStep, type JourneyStepIndex, type PathwayKey } from 'types';

interface Props {
  journeyStage: JourneyStep;
  careerPathway: PathwayKey | null;
}

export function JourneyProgress({ journeyStage, careerPathway }: Props) {
  const { gwxApiIsLoading } = useLearnerApi();

  const containerClasses = classNames(
    'p-5 bg-white rounded-xl min-h-[300px] drop-shadow-md h-auto md:pb-10',
    {
      'grid place-items-center': gwxApiIsLoading,
    },
  );

  return (
    <div className={containerClasses}>
      {gwxApiIsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className='text-lg font-gtflexa-medium'>Your journey</div>

          <div className='row-span-3 grid grid-rows justify-center text-center p-8'>
            <span className='text-4xl md:text-5xl font-gtflexa-bold pb-4 uppercase'>
              {capitalize(journeyStage)}
            </span>
            <div>
              {journeyStage !== 'DISCOVER' && journeyStage !== 'LEARN' && careerPathway && (
                <span>in&nbsp;</span>
              )}
              {journeyStage === 'LEARN' && <span>about&nbsp;</span>}
              {careerPathway && journeyStage !== 'DISCOVER' ? (
                <span>{startCase(toLower(careerPathway))}</span>
              ) : (
                <span>
                  {<span>careers in&nbsp;</span>}
                  {PATHWAY_KEYS.map((pathway, index) => {
                    if (index === PATHWAY_KEYS.length - 1) {
                      return <span key={pathway}>or {startCase(toLower(pathway))}</span>;
                    } else return <span key={pathway}>{startCase(toLower(pathway))},&nbsp;</span>;
                  })}
                </span>
              )}
            </div>
          </div>

          <div className='mt-5 sm:mt-2 w-full h-auto mx-auto pb-8 md:px-5'>
            <ProgressBar
              activeStepIndex={JOURNEY_STEPS_SEQUENCE.indexOf(journeyStage) as JourneyStepIndex}
            />
          </div>
        </>
      )}
    </div>
  );
}
