// Docs: https://www.sanity.io/docs/js-client#sanityclient

import { type ClientConfig, createClient } from '@sanity/client';

/* A dataset is a database where our content is stored.
 * We configure the Sanity client with the `production` or `development` dataset,
 * based on the mode Vite is running in.
 * Docs: https://www.sanity.io/docs/datasets
 */
type Dataset = 'production' | 'development';

const SANITY_PROJECT_ID_GREENWORKX_CONTENT = 'ox1k3pkb';

const dataset: Dataset = import.meta.env.VITE_SANITY_DATASET ?? 'development';

const config: ClientConfig = {
  projectId: SANITY_PROJECT_ID_GREENWORKX_CONTENT,
  dataset: dataset,
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2024-08-06', // use current date (YYYY-MM-DD) to target the latest API version
  perspective: 'published',
};

export const sanityClient = createClient(config);
