import { RankingCard } from '@components/Ranking/RankingCard';
import { Reorder } from 'framer-motion';
import type { Dispatch, SetStateAction } from 'react';
import type { AnswersContent, MotivatingFactorsKey } from 'types';

interface Props {
  isLoading: boolean;
  optionsRanked: MotivatingFactorsKey[];
  setOptionsRanked: Dispatch<SetStateAction<MotivatingFactorsKey[]>>;
  answersContent?: AnswersContent;
}

export function RankingCardList({
  isLoading,
  optionsRanked,
  setOptionsRanked,
  answersContent,
}: Props) {
  function handleSwap(index1: number, index2: number) {
    const optionsRankedCopy = [...optionsRanked];
    const temp = optionsRankedCopy[index1];
    optionsRankedCopy[index1] = optionsRankedCopy[index2];
    optionsRankedCopy[index2] = temp;
    setOptionsRanked(optionsRankedCopy);
  }

  return (
    <Reorder.Group
      axis='y'
      values={optionsRanked}
      onReorder={setOptionsRanked}
      className='mt-4 mb-12'
      as='ol' // ordered list
    >
      {optionsRanked.map((option, index) => {
        const answerContent = answersContent?.find((answer) => answer.keyCustom === option);
        return (
          <Reorder.Item
            key={option}
            value={option}
            dragListener={false}
            className='even:bg-grey/20'
          >
            <RankingCard
              key={option}
              isFirst={index === 0}
              isLast={index === optionsRanked.length - 1}
              handleSwap={handleSwap}
              index={index}
              content={answerContent?.answerText ?? []}
              isLoading={isLoading}
            />
          </Reorder.Item>
        );
      })}
    </Reorder.Group>
  );
}
