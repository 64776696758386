import { Callout } from '@components/Callout';
import { RecommendationList } from '@components/RecommendationList/RecommendationList';
import { Skeleton } from '@components/Skeleton';
import { PATHWAY_KEYS_TO_CAMEL_CASE } from '@constants';
import { staggerFadeIn } from '@lib/framer-motion/stagger_fade_in';
import { isPathwaySearchParam } from '@lib/recommend';
import { useContent } from '@lib/swr/useContent';
import { useLearnerApi } from '@lib/swr/useLearnerApi';
import { PortableText } from '@portabletext/react';
import type { FileRoutesByPath } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { getTypedObjectKeys } from '@util/get_typed_object_keys';
import { prepareStageOverrideComponents } from '@util/prepare_stage_override_text';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { selectedPathwayAtom } from 'store';

export const Route = createFileRoute('/learn')({
  component: LearnPage,
  validateSearch: (
    search: Record<string, unknown>,
  ): { pathway?: (typeof PATHWAY_KEYS_TO_CAMEL_CASE)[keyof typeof PATHWAY_KEYS_TO_CAMEL_CASE] } => {
    const { pathway } = search;
    if (isPathwaySearchParam(pathway)) {
      return { pathway };
    }
    return {};
  },
});

function LearnPage() {
  const [careerPathwayLocalStorage, setCareerPathwayLocalStorage] = useAtom(selectedPathwayAtom);

  const navigate = useNavigate();
  const searchParams = Route.useSearch();

  const { content, contentApiIsLoading } = useContent();
  const { learner, gwxApiIsLoading } = useLearnerApi();

  const pathwayKeyFromSearchParams = searchParams.pathway
    ? getTypedObjectKeys(PATHWAY_KEYS_TO_CAMEL_CASE).find(
        (key) => PATHWAY_KEYS_TO_CAMEL_CASE[key] === searchParams.pathway,
      )
    : undefined;
  if (pathwayKeyFromSearchParams) setCareerPathwayLocalStorage(pathwayKeyFromSearchParams);

  const careerPathway =
    pathwayKeyFromSearchParams ?? learner?.careerPathway ?? careerPathwayLocalStorage;

  if (!pathwayKeyFromSearchParams && !careerPathway) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw navigate({ to: '/' });
  }

  const pathwayContent = content?.careerPathways.find(
    (pathway) => pathway.keyCustom === careerPathway,
  );
  const helperMessage = pathwayContent?.learnStage.helperMessage;
  const recommendations = pathwayContent?.learnStage.recommendations;
  const stageOverrideBackValue = pathwayContent?.learnStage.journeyBack;
  const stageOverrideForwardValue = pathwayContent?.learnStage.journeyForward;

  const handleLinkClick = useCallback(
    (destination: keyof FileRoutesByPath) => {
      void navigate({
        to: destination,
        search:
          destination === '/train' ? { ...searchParams } : { ...searchParams, pathway: undefined },
      });
    },
    [navigate, searchParams],
  );

  /** Memoize the custom portable text objects to reduce re-renders:
   * https://github.com/portabletext/react-portabletext?tab=readme-ov-file#customizing-components*/
  const stageOverrideBackComponents = useMemo(() => {
    return prepareStageOverrideComponents(() => {
      handleLinkClick('/discover');
    });
  }, [handleLinkClick]);
  const stageOverrideForwardComponents = useMemo(() => {
    return prepareStageOverrideComponents(() => {
      handleLinkClick('/train');
    });
  }, [handleLinkClick]);

  return (
    <div
      className='py-6 flex flex-col gap-10 mt-4'
      aria-busy={contentApiIsLoading || gwxApiIsLoading}
      aria-hidden={contentApiIsLoading || gwxApiIsLoading}
      aria-live='polite'
    >
      {contentApiIsLoading ? (
        <Skeleton variant='paragraph' />
      ) : (
        helperMessage && (
          <Callout>
            <p>{helperMessage}</p>
          </Callout>
        )
      )}

      {contentApiIsLoading || gwxApiIsLoading ? (
        <>
          <Skeleton variant='paragraph' />
          <Skeleton variant='paragraph' />
          <Skeleton variant='paragraph' />
        </>
      ) : recommendations ? (
        <motion.div
          className='flex flex-col gap-4 md:gap-8'
          variants={staggerFadeIn}
          animate='animate'
          initial='initial'
        >
          <RecommendationList
            recommendations={[recommendations[0]]}
            title='Next step'
            hasTopPick={true}
            animation={staggerFadeIn}
          />
          {recommendations.length > 1 && (
            <RecommendationList
              recommendations={recommendations.slice(1)}
              title='Other steps'
              animation={staggerFadeIn}
            />
          )}

          <div className='flex flex-col gap-2'>
            {stageOverrideBackValue && (
              <PortableText
                value={stageOverrideBackValue}
                components={stageOverrideBackComponents}
              />
            )}
            {stageOverrideForwardValue && (
              <PortableText
                value={stageOverrideForwardValue}
                components={stageOverrideForwardComponents}
              />
            )}
          </div>
        </motion.div>
      ) : null}
    </div>
  );
}
