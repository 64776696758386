import type { FileRoutesByPath } from '@tanstack/react-router';
import { z } from 'zod';

export const pageTitles: Record<keyof FileRoutesByPath, string> = {
  '/': 'Welcome',
  '/discover': 'My Career',
  '/discovery': 'Discovery',
  '/rank': 'Discovery',
  '/learn': 'My Career',
  '/train': 'My Career',
  '/work': 'My Career',
  '/dashboard': 'My Career',
};

export const utmTrackingSearchParams = z.object({
  /* eslint-disable camelcase */
  utm_campaign: z.string().min(1).optional(),
  utm_source: z.string().min(1).optional(),
  /* eslint-enable camelcase */
});

export type UtmTrackingSearchParams = z.infer<typeof utmTrackingSearchParams>;
