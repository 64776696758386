import { IconButton } from '@components/IconButton';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { IoMdClose } from 'react-icons/io';

interface Props extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  handleDismiss?: () => void;
  transitionKey?: string;
}

export function Callout({ children, handleDismiss, transitionKey }: Props) {
  const containerClasses = classNames('p-4 rounded-xl bg-[#E6E6E6]', {
    'flex gap-4': !!handleDismiss,
  });

  return handleDismiss ? (
    <motion.div
      key={transitionKey}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={containerClasses}
    >
      {children}
      <div>
        <IconButton
          onClick={handleDismiss}
          aria-label='Close callout'
          variant='ghost'
          icon={<IoMdClose />}
        />
      </div>
    </motion.div>
  ) : (
    <div className={containerClasses}>{children}</div>
  );
}
