import { ProgressBarRail } from '@components/ProgressBar/ProgressBarRail';
import { ProgressBarStep } from '@components/ProgressBar/ProgressBarStep';
import { JOURNEY_STEPS_SEQUENCE } from '@constants';
import { useLocation } from '@tanstack/react-router';
import { useAtomValue } from 'jotai';
import { router } from 'main';
import { useState } from 'react';
import { isAlreadyWorkingAtom } from 'store';
import { type JourneyStepIndex } from 'types';

interface ProgressBarProps {
  activeStepIndex?: JourneyStepIndex;
}

export function ProgressBar({ activeStepIndex: activeStepIndexProp }: ProgressBarProps) {
  const [activeStepIndex, setActiveStepIndex] = useState<JourneyStepIndex>(0);

  const isAlreadyWorking = useAtomValue(isAlreadyWorkingAtom);

  const pathname = useLocation({
    select: (location) => location.pathname,
  });

  function animate() {
    if (activeStepIndexProp !== undefined) {
      setActiveStepIndex(activeStepIndexProp);
    } else if (isAlreadyWorking && pathname === router.routesByPath['/work'].id) {
      setActiveStepIndex(3);
    } else if (pathname === router.routesByPath['/train'].id) {
      setActiveStepIndex(2);
    } else if (pathname === router.routesByPath['/learn'].id) {
      setActiveStepIndex(1);
    } else if (pathname === router.routesByPath['/discover'].id) {
      setActiveStepIndex(0);
    }
  }

  setTimeout(animate, 500);

  return (
    <div>
      <ProgressBarRail activeStepIndex={activeStepIndex} />
      <div className='flex justify-between font-gtflexa-bold -mt-4 sm:-mt-8'>
        {JOURNEY_STEPS_SEQUENCE.map((step, index) => (
          <ProgressBarStep
            key={step}
            isActive={index === activeStepIndex}
            number={index + 1}
            label={step}
          />
        ))}
      </div>
    </div>
  );
}
