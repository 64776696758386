import { IconButton } from '@components/IconButton';
import { GwxLogoTextWhite } from '@components/Icons/GwxLogoTextWhite';
import { NavigationListItem } from '@components/Pages/Dashboard/Navigation/NavigationListItem';
import { Skeleton } from '@components/Skeleton';
import { GWX_API_COOKIE_EXPIRED_VALUE } from '@lib/gwx_api';
import { useLearnerApi } from '@lib/swr/useLearnerApi';
import { useNavigate } from '@tanstack/react-router';
import classNames from 'classnames';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { feedbackDialogAtom } from 'store';

export function Navigation() {
  const { learner, gwxApiIsLoading } = useLearnerApi();
  const navigate = useNavigate();

  const setFeedbackDialogIsOpen = useSetAtom(feedbackDialogAtom);

  const [menuIsExpanded, setMenuIsExpanded] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    setIsAuthenticated(!!learner);
  }, [learner]);

  function handleHomeClick() {
    void navigate({
      to: '/',
    });
  }

  function handleLogOutClick() {
    document.cookie = GWX_API_COOKIE_EXPIRED_VALUE;
    window.location.reload();
  }

  function handleMenuButtonClick() {
    setMenuIsExpanded((previous) => !previous);
  }

  function handleFeedbackButtonClick() {
    setFeedbackDialogIsOpen(true);
  }

  const containerClasses = classNames(
    'w-full sm:h-fit sm:w-32 bg-forest text-white sm:rounded-lg p-4 text-lg',
    {
      'h-16': !menuIsExpanded,
      'h-fit': menuIsExpanded,
    },
  );

  const navClasses = classNames('sm:block', {
    hidden: !menuIsExpanded,
  });

  const menuButtonLabel = `${menuIsExpanded ? 'Close' : 'Open'} navigation menu`;

  const profileLinkLabel = isAuthenticated ? 'Profile' : 'Log in';
  const profileLinkHref = isAuthenticated
    ? 'https://profile.greenworkx.org'
    : (`https://profile.greenworkx.org/login?redirectTo=${window.location.href}` as const);
  const profileLinkAnchorTarget = isAuthenticated ? '_blank' : undefined;

  return (
    <div className={containerClasses}>
      <div className='sm:hidden flex flex-row justify-between items-center'>
        <GwxLogoTextWhite />
        <IconButton
          icon={<GiHamburgerMenu />}
          onClick={handleMenuButtonClick}
          colour='white'
          variant='ghost'
          aria-label={menuButtonLabel}
          aria-expanded={menuIsExpanded}
          aria-controls='dashboard-nav'
        />
      </div>
      <nav className={navClasses} id='dashboard-nav'>
        <ul className='flex flex-col gap-4 sm:text-left text-center py-8 sm:py-0'>
          {gwxApiIsLoading ? (
            <>
              <Skeleton variant='line' />
              <Skeleton variant='line' />
              <Skeleton variant='line' />
            </>
          ) : (
            <>
              <NavigationListItem onClick={handleHomeClick}>Home</NavigationListItem>
              <NavigationListItem href={profileLinkHref} target={profileLinkAnchorTarget}>
                {profileLinkLabel}
              </NavigationListItem>
              {isAuthenticated && (
                <NavigationListItem onClick={handleLogOutClick}>Log out</NavigationListItem>
              )}
              <NavigationListItem onClick={handleFeedbackButtonClick}>Feedback</NavigationListItem>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
}
