import type { Milestones } from '@greenworkx/gwx-api/dist/spec/schemas';
import trophy from '@images/gold-trophy.png';

type Milestone = Milestones[number];

export interface PromptContent {
  relatedMilestone: Milestone;
  title: string;
  message: string;
  imageLink: string;
  altText: string;
}

export const gwxUserPrompts: PromptContent[] = [
  {
    relatedMilestone: 'ENERGY_ADVISOR_ACADEMY',
    title: 'Congratulations!',
    message:
      'Congratulations on graduating from the Energy Advisor Academy, your hard work has paid off!',
    imageLink: trophy,
    altText: 'Gold trophy',
  },
  {
    relatedMilestone: 'DEA_ACADEMY',
    title: 'Congratulations!',
    message: `Congratulations, you're an official graduate of the DEA Academy!`,
    imageLink: trophy,
    altText: 'Gold trophy',
  },
];
