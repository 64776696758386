import type { Learner } from '@greenworkx/gwx-api/dist/spec/schemas';
import type { Milestones } from '@greenworkx/gwx-api/dist/spec/schemas';
import { createGwxApiClient } from '@lib/gwx_api';
import type { KeyedMutator } from 'swr';

interface Props {
  completedMilestones: Milestones;
  invalidateLearner: KeyedMutator<Learner>;
}

export async function updateLearnerMilestones({ completedMilestones, invalidateLearner }: Props) {
  const gwxApiClient = createGwxApiClient();
  try {
    await gwxApiClient
      .updateLearner(
        {
          milestones: {
            completed: completedMilestones,
            completedAndSeen: completedMilestones,
          },
        },
        { params: { id: 'self' } },
      )
      .then(async () => {
        await invalidateLearner();
      });
  } catch {
    return;
  }
}
