import type { FileRoutesByPath } from '@tanstack/react-router';
import type { JourneyPath, JourneyStep, PathwayKey, PathwayKeyCamelCase } from 'types';

export const JOURNEY_STEPS_SEQUENCE = ['DISCOVER', 'LEARN', 'TRAIN', 'WORK'] as const;

export const PATH_TO_JOURNEY_STEP: Record<JourneyPath, JourneyStep> = {
  '/discover': 'DISCOVER',
  '/discovery': 'DISCOVER',
  '/rank': 'DISCOVER',
  '/learn': 'LEARN',
  '/train': 'TRAIN',
  '/work': 'WORK',
};

export const ROUTE_PATHS_IN_NAVIGATION_ORDER: readonly (keyof FileRoutesByPath)[] = [
  '/',
  '/discovery',
  '/rank',
  '/discover',
  '/learn',
  '/train',
  '/work',
];

export const PATHWAY_KEYS: PathwayKey[] = [
  'ENERGY_ADVICE',
  'ENERGY_ASSESSMENT',
  'LOW_CARBON_INSTALLATION',
] as const;

export const PATHWAY_KEYS_TO_CAMEL_CASE = {
  ENERGY_ADVICE: 'energyAdvice',
  ENERGY_ASSESSMENT: 'energyAssessment',
  LOW_CARBON_INSTALLATION: 'lowCarbonInstallation',
} as const satisfies Record<PathwayKey, PathwayKeyCamelCase>;

export const PATHWAY_SEARCH_PARAM_VALUES = [
  'energyAdvice',
  'energyAssessment',
  'lowCarbonInstallation',
] as const satisfies PathwayKeyCamelCase[];
