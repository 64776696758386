import { Button } from '@components/Button';
import { Callout } from '@components/Callout';
import { Dialog } from '@components/Dialog';
import { Footer } from '@components/Footer';
import { H1 } from '@components/Headings';
import { GwxLogoTextBlack } from '@components/Icons/GwxLogoTextBlack/GwxLogoTextBlack';
import { GwxUserOutline } from '@components/Icons/GwxUserOutline';
import { Navigation } from '@components/Pages/Dashboard/Navigation';
import { RadialProgressBar } from '@components/RadialProgressBar';
import { RecommendationList } from '@components/RecommendationList/RecommendationList';
import { Skeleton } from '@components/Skeleton';
import type { LearnerGeneralPartial } from '@greenworkx/gwx-api/dist/spec/schemas';
import logo from '@images/logo_324x324.jpg';
import { staggerFadeIn } from '@lib/framer-motion/stagger_fade_in';
import { createGwxApiClient } from '@lib/gwx_api';
import { useContent } from '@lib/swr/useContent';
import { PortableText } from '@portabletext/react';
import { useNavigate } from '@tanstack/react-router';
import { getStageOverrides } from '@util/get_stage_overrides';
import { updateLearnerMilestones } from '@util/milestones/update_learner_milestones';
import { prepareStageOverrideComponents } from '@util/prepare_stage_override_text';
import { AnimatePresence } from 'framer-motion';
import { usePromptHandler } from 'hooks/usePromptHandler';
import { useAtom, useAtomValue } from 'jotai';
import { genericActionContent } from 'lib/recommend';
import { useLearnerApi } from 'lib/swr/useLearnerApi';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Route } from 'routes/dashboard';
import {
  feedbackDialogAtom,
  gwxApiIsPatchingAtom,
  isFinalPromptDismissedAtom,
  journeyStageAtom,
  selectedPathwayAtom,
} from 'store';
import { dashboardWelcomeDismissed } from 'store';
import type { JourneyStep, RecommendationContent, StageOverrideContent } from 'types';

import { GenericAction } from './GenericAction';
import { JourneyProgress } from './JourneyProgress';
import { NextAction } from './NextAction';

export function Dashboard() {
  const { learner, gwxApiIsLoading, invalidateLearner } = useLearnerApi();
  const { content, contentApiIsLoading } = useContent();

  const mainElementRef = useRef<HTMLElement>(null);

  const navigate = useNavigate();
  const searchParams = Route.useSearch();

  const [welcomeDismissed, setWelcomeDismissed] = useAtom(dashboardWelcomeDismissed);
  const [feedbackDialogIsOpen, setFeedbackDialogIsOpen] = useAtom(feedbackDialogAtom);
  const isFinalPromptDismissed = useAtomValue(isFinalPromptDismissedAtom);

  const [gwxApiIsPatching, setGwxApiIsPatching] = useAtom(gwxApiIsPatchingAtom);
  const careerPathwayLocalStorage = useAtomValue(selectedPathwayAtom);
  const [journeyStageLocalStorage, setJourneyStageLocalStorage] = useAtom(journeyStageAtom);

  const careerPathway = gwxApiIsPatching
    ? careerPathwayLocalStorage
    : (learner?.careerPathway ?? careerPathwayLocalStorage);

  const journeyStage = gwxApiIsPatching
    ? journeyStageLocalStorage
    : (learner?.journeyStage ?? journeyStageLocalStorage);

  const profileCompletionPercentage = learner?.profileCompletion
    ? learner.profileCompletion * 100
    : 0;

  const pathwayContent = content?.careerPathways.find(
    (pathway) => pathway.keyCustom === careerPathway,
  );

  const completedMilestones = learner?.milestones?.completed;

  function getRecommendations(): RecommendationContent[] | undefined {
    if (!pathwayContent) return;
    switch (journeyStage) {
      case 'LEARN':
        return pathwayContent.learnStage.recommendations;
      case 'TRAIN':
        return pathwayContent.trainStage.recommendations;
      case 'WORK':
        return pathwayContent.workStage.recommendations;
      default:
        return;
    }
  }

  function getStageOverridesContent(): StageOverrideContent[] | undefined {
    if (!pathwayContent) return;
    switch (journeyStage) {
      case 'LEARN':
        return [pathwayContent.learnStage.journeyForward, pathwayContent.learnStage.journeyBack];
      case 'TRAIN':
        return [pathwayContent.trainStage.journeyForward, pathwayContent.trainStage.journeyBack];
      case 'WORK':
        return [pathwayContent.workStage.journeyBack];
      default:
        return;
    }
  }

  const nextRecommendation = getRecommendations()?.[0];
  const otherRecommendations = getRecommendations()?.slice(1);

  const stageOverridesContent = getStageOverridesContent();
  const stageOverrideValues = getStageOverrides(journeyStage);

  function handleWelcomeCalloutDismiss() {
    setWelcomeDismissed(true);
  }

  const updateLearner = useCallback(
    async ({ journeyStage }: LearnerGeneralPartial) => {
      const gwxApiClient = createGwxApiClient();
      try {
        await gwxApiClient
          .updateLearner({ journeyStage: journeyStage }, { params: { id: 'self' } })
          .then(async () => {
            await invalidateLearner();
          });
      } catch {
        return;
      }
    },
    [invalidateLearner],
  );

  const handleStageOverrideClick = useCallback(
    async (newStage: JourneyStep) => {
      setJourneyStageLocalStorage(newStage);
      setGwxApiIsPatching(true);
      if (journeyStage === 'WORK' && newStage === 'DISCOVER') {
        void navigate({
          to: '/rank',
          search: searchParams,
        });
        await updateLearner({ journeyStage: 'DISCOVER' }).then(() => {
          setGwxApiIsPatching(false);
        });
      } else {
        setTimeout(() => {
          mainElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0);
        await updateLearner({ journeyStage: newStage }).then(() => {
          setGwxApiIsPatching(false);
        });
      }
    },
    [
      journeyStage,
      navigate,
      searchParams,
      setGwxApiIsPatching,
      setJourneyStageLocalStorage,
      updateLearner,
    ],
  );

  /** Memoize the custom portable text objects to reduce re-renders:
   * https://github.com/portabletext/react-portabletext?tab=readme-ov-file#customizing-components
   * */
  const stageOverrideBackComponents = useMemo(() => {
    return prepareStageOverrideComponents(() => {
      if (stageOverrideValues.backStage)
        void handleStageOverrideClick(stageOverrideValues.backStage);
    });
  }, [stageOverrideValues, handleStageOverrideClick]);
  const stageOverrideForwardComponents = useMemo(() => {
    return prepareStageOverrideComponents(() => {
      if (stageOverrideValues.forwardStage)
        void handleStageOverrideClick(stageOverrideValues.forwardStage);
    });
  }, [stageOverrideValues, handleStageOverrideClick]);

  const feedbackDialogContent = content?.feedback;

  const { isPromptVisible, currentPromptIndex, unseenPrompts = [], onDismiss } = usePromptHandler();
  const { title, message, imageLink, altText } = unseenPrompts[currentPromptIndex] || {};

  useEffect(() => {
    if (completedMilestones && isFinalPromptDismissed) {
      void updateLearnerMilestones({ completedMilestones, invalidateLearner });
    }
  }, [isFinalPromptDismissed, completedMilestones, invalidateLearner]);

  return (
    <>
      {unseenPrompts[currentPromptIndex] && (
        <Dialog
          title={title}
          description={message}
          variant='celebration'
          imageLink={imageLink}
          altText={altText}
          isOpen={isPromptVisible}
          onClose={onDismiss}
        />
      )}

      <main className='bg-[#f8f8f8] px-5 pb-5 pt-0 sm:px-10 sm:pt-10 sm:pb-16' ref={mainElementRef}>
        <div
          className='max-w-screen-lg mx-auto'
          aria-busy={contentApiIsLoading || gwxApiIsLoading}
          aria-hidden={contentApiIsLoading || gwxApiIsLoading}
          aria-live='polite'
        >
          <div className='sm:block hidden mb-12'>
            <GwxLogoTextBlack />
          </div>
          <div className='flex flex-col sm:flex-row gap-8'>
            <div className='flex flex-col shrink-0 -mx-5 sm:mx-0'>
              <Navigation />
            </div>

            <div className='flex flex-col gap-5 md:gap-10'>
              {gwxApiIsLoading ? (
                <Skeleton variant='heading' />
              ) : (
                <H1>{learner?.firstName ? `Welcome ${learner.firstName}!` : 'Welcome!'}</H1>
              )}
              {!welcomeDismissed && contentApiIsLoading && <Skeleton variant='paragraph' />}
              <AnimatePresence>
                {!welcomeDismissed && content?.dashboard && (
                  <Callout
                    handleDismiss={handleWelcomeCalloutDismiss}
                    transitionKey='dashboard-welcome-callout'
                  >
                    <div className='shrink-0' aria-hidden={true}>
                      <img
                        src={logo}
                        alt=''
                        className='md:w-24 sm:w-12 sm:block hidden aspect-square rounded-full'
                      ></img>
                    </div>
                    <div className='self-center'>{content.dashboard.welcomeMessage}</div>
                  </Callout>
                )}
              </AnimatePresence>
              <JourneyProgress careerPathway={careerPathway} journeyStage={journeyStage} />
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
                {nextRecommendation ? (
                  <NextAction recommendation={nextRecommendation} />
                ) : (
                  // TODO: define default `DISCOVER` recommendation in Sanity
                  <NextAction
                    recommendation={{
                      _id: 'discover',
                      title: 'Select a career pathway to start your journey',
                      url: '',
                      cta: { label: 'Find out more' },
                      description: '',
                      isComingSoon: false,
                      internalLink: '/discover',
                    }}
                  />
                )}
                {learner ? (
                  <GenericAction action={genericActionContent.completeProfile}>
                    <RadialProgressBar completion={profileCompletionPercentage} />
                  </GenericAction>
                ) : (
                  <GenericAction action={genericActionContent.register}>
                    <GwxUserOutline size={150} fill={'#dadada'} />
                  </GenericAction>
                )}
              </div>
              {otherRecommendations && otherRecommendations.length > 0 && (
                <RecommendationList
                  recommendations={otherRecommendations}
                  animation={staggerFadeIn}
                  title='More next steps'
                />
              )}

              <div className='flex flex-col gap-2'>
                {stageOverridesContent?.map((override, index) => (
                  <PortableText
                    key={index}
                    value={override}
                    components={
                      journeyStage === 'WORK'
                        ? stageOverrideBackComponents
                        : index === 0
                          ? stageOverrideForwardComponents
                          : stageOverrideBackComponents
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <Dialog
        title={feedbackDialogContent?.title ?? 'Feedback'}
        description={
          feedbackDialogContent?.body ??
          `We'd appreciate if you gave us some feedback on your experience using this new app.`
        }
        isOpen={feedbackDialogIsOpen}
        onClose={() => {
          setFeedbackDialogIsOpen(false);
        }}
      >
        <Button href='https://airtable.com/appoBnn8cRrRiCYaR/pagS4S3udyMl3bMyI/form?prefill_About=Journey+App'>
          {feedbackDialogContent?.cta.label ?? 'Submit feedback'}
        </Button>
      </Dialog>
    </>
  );
}
