import { newClient } from '@greenworkx/gwx-api';
import Cookies from 'js-cookie';

export const GWX_API_COOKIE_NAME = 'gwx-api-token';
export const GWX_API_COOKIE_DOMAIN = '.greenworkx.org';
export const GWX_API_COOKIE_EXPIRED_VALUE = `${GWX_API_COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${GWX_API_COOKIE_DOMAIN};path=/;`;

function createGwxApiClient() {
  const baseUrl = 'https://api.greenworkx.org';
  const cookieToken = Cookies.get(GWX_API_COOKIE_NAME);
  return newClient(baseUrl, () => Promise.resolve(cookieToken));
}

export type GwxApiClient = ReturnType<typeof newClient>;

export { createGwxApiClient };
