import { sanityClient } from 'lib/sanity/client';
import type { ALL_CONTENTResult } from 'lib/sanity/generated/types';
import { ALL_CONTENT } from 'lib/sanity/queries';
import useSWRImmutable from 'swr/immutable';

export function useContent() {
  async function getContent(): Promise<ALL_CONTENTResult> {
    const content = await sanityClient.fetch<ALL_CONTENTResult>(ALL_CONTENT);
    return content;
  }

  const { data, isLoading, error } = useSWRImmutable<ALL_CONTENTResult, Error>(
    'getContent',
    getContent,
  );

  return {
    content: data,
    contentApiIsLoading: isLoading,
    contentApiError: error,
  };
}
