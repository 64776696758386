import classNames from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';
import { FiExternalLink } from 'react-icons/fi';
interface Props extends ComponentPropsWithoutRef<'a'> {
  href: string;
  onClick?: () => void;
  variant?: 'inline' | 'heading';
}

export function LinkExternal({ href, onClick, variant = 'inline', children }: Props) {
  const classes = classNames('underline text-left', {
    'transition-all decoration-transparent hover:decoration-black duration-500 hover:duration-150':
      variant === 'heading',
  });

  return (
    <a className={classes} href={href} target='_blank' onClick={onClick}>
      {children}
      {variant === 'inline' && (
        <span className='inline-block align-middle ml-2' aria-hidden='true'>
          <FiExternalLink />
        </span>
      )}
    </a>
  );
}
