import { Button } from '@components/Button';
import { Dialog } from '@components/Dialog';
import { H3 } from '@components/Headings';
import { h3Classes } from '@components/Headings/styles';
import { IconButton } from '@components/IconButton';
import { GwxArrowRightIcon } from '@components/Icons/GwxArrowRight';
import { LinkExternal } from '@components/Links/LinkExternal';
import { Pill } from '@components/Pill';
import { Timeline } from '@components/Timeline';
import { isPathway } from '@lib/helpers';
import classNames from 'classnames';
import { useSetAtom } from 'jotai';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import type { RecommendationWithPathwayKey } from 'routes/discover';
import { feedbackDialogAtom } from 'store';
import type { PathwayKey, RecommendationContent } from 'types';

interface Props {
  recommendation: RecommendationWithPathwayKey | RecommendationContent;
  isTopPick?: boolean;
  isSelected?: boolean;
  isSelectable?: boolean;
  setSelectedPathwayButton?: Dispatch<SetStateAction<PathwayKey | null>>;
}

export function Card({
  recommendation,
  isTopPick = false,
  isSelected = false,
  isSelectable = false,
  setSelectedPathwayButton,
}: Props) {
  const [pathwayDialogIsOpen, setPathwayDialogIsOpen] = useState<boolean>(false);
  const setFeedbackDialogIsOpen = useSetAtom(feedbackDialogAtom);

  const contentIsPathway = isPathway(recommendation);

  function handleClick() {
    setFeedbackDialogIsOpen(true);
  }

  function handleSelectableClick(key: PathwayKey) {
    if (setSelectedPathwayButton) {
      setSelectedPathwayButton(key);
    }
  }

  const cardClasses = classNames(
    'rounded-xl p-4 text-left shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] border-solid border-2 focus-visible:duration-150 motion-reduce:transition-none focus-visible:outline-offset-2',
    {
      // selectable cards
      'border-black': isSelectable ? isSelected : isTopPick,
      block: isPathway(recommendation),
    },
  );

  const layoutClasses = classNames('flex flex-col', cardClasses, {
    'gap-4': !contentIsPathway && !recommendation.isComingSoon,
    'gap-2': !contentIsPathway && recommendation.isComingSoon,
  });

  const title = contentIsPathway ? recommendation.content?.title : recommendation.title;

  function handleMoreInfoButtonClick() {
    setPathwayDialogIsOpen(true);
  }

  return (
    <>
      <div className={layoutClasses}>
        <div className='flex justify-between'>
          <div className='grow flex gap-2 flex-wrap justify-between'>
            <>
              <div className={h3Classes}>
                {!isSelectable && !isPathway(recommendation) && (
                  <LinkExternal href={recommendation.url} variant='heading'>
                    {title}
                  </LinkExternal>
                )}
                {isSelectable && (
                  <Button
                    shape='text'
                    textCase='normal'
                    className={h3Classes}
                    onClick={() => {
                      if (isPathway(recommendation)) handleSelectableClick(recommendation.key);
                    }}
                  >
                    {title}
                  </Button>
                )}
              </div>
            </>

            {isTopPick && <Pill>Recommended</Pill>}

            {!isPathway(recommendation) && recommendation.isComingSoon && <Pill>Coming soon</Pill>}
          </div>
          {contentIsPathway && (
            <div className='shrink-0 grow-0'>
              <IconButton
                icon={<IoIosInformationCircleOutline />}
                aria-label='More information'
                onClick={handleMoreInfoButtonClick}
                variant='ghost'
                iconSize='1.5em'
              />
            </div>
          )}
        </div>

        <div className='flex gap-2'>
          <p className='grow mb-4'>
            {contentIsPathway ? recommendation.content?.description : recommendation.description}
          </p>
          {!isTopPick && !isSelectable && !contentIsPathway && (
            <div className='grow-0 flex flex-col justify-end'>
              <LinkExternal
                onClick={handleClick}
                href={recommendation.url}
                variant='heading'
                tabIndex={-1}
              >
                <GwxArrowRightIcon />
                <span className='sr-only'>{recommendation.title}</span>
              </LinkExternal>
            </div>
          )}
        </div>

        {isTopPick && !isSelectable && !contentIsPathway && (
          <Button onClick={handleClick} href={recommendation.url} role='link'>
            {recommendation.cta.label}
          </Button>
        )}
      </div>

      {contentIsPathway && recommendation.content && (
        <Dialog
          title={recommendation.content.title}
          description={recommendation.content.responsibilities}
          isOpen={pathwayDialogIsOpen}
          onClose={() => {
            setPathwayDialogIsOpen(false);
          }}
        >
          <div className='flex flex-col gap-2'>
            <H3>Jobs in this pathway</H3>
            <ul>
              {recommendation.content.careers.map((career) => (
                <li key={career._id}>
                  {career.url ? (
                    <LinkExternal href={career.url}>{career.title}</LinkExternal>
                  ) : (
                    <div>{career.title}</div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className='flex flex-col gap-2'>
            <H3>Timeline</H3>
            <Timeline content={recommendation.content.timeline} />
          </div>
        </Dialog>
      )}
    </>
  );
}
