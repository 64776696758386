import classNames from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';

interface Props extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  variant?: 'inline' | 'heading';
}

export function LinkInternal({ onClick, variant = 'inline', children }: Props) {
  const classes = classNames('underline text-left', {
    'transition-all decoration-transparent hover:decoration-black duration-500 hover:duration-150':
      variant === 'heading',
    'max-w-fit': variant === 'inline',
  });

  return (
    <button className={classes} onClick={onClick} role='link'>
      {children}
    </button>
  );
}
