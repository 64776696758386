import { LinkInternal } from '@components/Links/LinkInternal';
import type { PortableTextReactComponents } from '@portabletext/react';

// Docs: https://github.com/portabletext/react-portabletext?tab=readme-ov-file#customizing-components

export function prepareStageOverrideComponents(
  handleClick: () => void,
): Partial<PortableTextReactComponents> {
  return {
    marks: {
      linkText: ({ children }) => <LinkInternal onClick={handleClick}>{children}</LinkInternal>,
    },
  };
}
