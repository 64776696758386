/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as WorkImport } from './routes/work'
import { Route as TrainImport } from './routes/train'
import { Route as RankImport } from './routes/rank'
import { Route as LearnImport } from './routes/learn'
import { Route as DiscoveryImport } from './routes/discovery'
import { Route as DiscoverImport } from './routes/discover'
import { Route as DashboardImport } from './routes/dashboard'
import { Route as IndexImport } from './routes/index'

// Create/Update Routes

const WorkRoute = WorkImport.update({
  id: '/work',
  path: '/work',
  getParentRoute: () => rootRoute,
} as any)

const TrainRoute = TrainImport.update({
  id: '/train',
  path: '/train',
  getParentRoute: () => rootRoute,
} as any)

const RankRoute = RankImport.update({
  id: '/rank',
  path: '/rank',
  getParentRoute: () => rootRoute,
} as any)

const LearnRoute = LearnImport.update({
  id: '/learn',
  path: '/learn',
  getParentRoute: () => rootRoute,
} as any)

const DiscoveryRoute = DiscoveryImport.update({
  id: '/discovery',
  path: '/discovery',
  getParentRoute: () => rootRoute,
} as any)

const DiscoverRoute = DiscoverImport.update({
  id: '/discover',
  path: '/discover',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/discover': {
      id: '/discover'
      path: '/discover'
      fullPath: '/discover'
      preLoaderRoute: typeof DiscoverImport
      parentRoute: typeof rootRoute
    }
    '/discovery': {
      id: '/discovery'
      path: '/discovery'
      fullPath: '/discovery'
      preLoaderRoute: typeof DiscoveryImport
      parentRoute: typeof rootRoute
    }
    '/learn': {
      id: '/learn'
      path: '/learn'
      fullPath: '/learn'
      preLoaderRoute: typeof LearnImport
      parentRoute: typeof rootRoute
    }
    '/rank': {
      id: '/rank'
      path: '/rank'
      fullPath: '/rank'
      preLoaderRoute: typeof RankImport
      parentRoute: typeof rootRoute
    }
    '/train': {
      id: '/train'
      path: '/train'
      fullPath: '/train'
      preLoaderRoute: typeof TrainImport
      parentRoute: typeof rootRoute
    }
    '/work': {
      id: '/work'
      path: '/work'
      fullPath: '/work'
      preLoaderRoute: typeof WorkImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRoute
  '/discover': typeof DiscoverRoute
  '/discovery': typeof DiscoveryRoute
  '/learn': typeof LearnRoute
  '/rank': typeof RankRoute
  '/train': typeof TrainRoute
  '/work': typeof WorkRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRoute
  '/discover': typeof DiscoverRoute
  '/discovery': typeof DiscoveryRoute
  '/learn': typeof LearnRoute
  '/rank': typeof RankRoute
  '/train': typeof TrainRoute
  '/work': typeof WorkRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRoute
  '/discover': typeof DiscoverRoute
  '/discovery': typeof DiscoveryRoute
  '/learn': typeof LearnRoute
  '/rank': typeof RankRoute
  '/train': typeof TrainRoute
  '/work': typeof WorkRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/dashboard'
    | '/discover'
    | '/discovery'
    | '/learn'
    | '/rank'
    | '/train'
    | '/work'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/dashboard'
    | '/discover'
    | '/discovery'
    | '/learn'
    | '/rank'
    | '/train'
    | '/work'
  id:
    | '__root__'
    | '/'
    | '/dashboard'
    | '/discover'
    | '/discovery'
    | '/learn'
    | '/rank'
    | '/train'
    | '/work'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRoute: typeof DashboardRoute
  DiscoverRoute: typeof DiscoverRoute
  DiscoveryRoute: typeof DiscoveryRoute
  LearnRoute: typeof LearnRoute
  RankRoute: typeof RankRoute
  TrainRoute: typeof TrainRoute
  WorkRoute: typeof WorkRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRoute: DashboardRoute,
  DiscoverRoute: DiscoverRoute,
  DiscoveryRoute: DiscoveryRoute,
  LearnRoute: LearnRoute,
  RankRoute: RankRoute,
  TrainRoute: TrainRoute,
  WorkRoute: WorkRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/dashboard",
        "/discover",
        "/discovery",
        "/learn",
        "/rank",
        "/train",
        "/work"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard.tsx"
    },
    "/discover": {
      "filePath": "discover.tsx"
    },
    "/discovery": {
      "filePath": "discovery.tsx"
    },
    "/learn": {
      "filePath": "learn.tsx"
    },
    "/rank": {
      "filePath": "rank.tsx"
    },
    "/train": {
      "filePath": "train.tsx"
    },
    "/work": {
      "filePath": "work.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
