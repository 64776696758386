import { motion } from 'framer-motion';
import type { ReactNode } from 'react';

export interface FadeInOutProps {
  children: ReactNode;
  transitionKey: string;
  isVisible?: boolean;
}

export function TransitionFadeInOut({ children, transitionKey, isVisible = true }: FadeInOutProps) {
  return isVisible ? (
    <motion.div
      key={transitionKey}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  ) : null;
}
