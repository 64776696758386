import { PATHWAY_KEYS, PATHWAY_SEARCH_PARAM_VALUES } from '@constants';
import type { MotivatingFactorsKey, PathwayKey, PathwayKeyCamelCase } from 'types';

import type { ExternalLink, InternalLink } from './link';
export interface GenericUserAction {
  heading: string;
  buttonTitle: string;
  href: ExternalLink | InternalLink;
}

export const genericActionContent: Record<string, GenericUserAction> = {
  register: {
    heading: 'Create an account and save your progress',
    buttonTitle: 'Create account',
    href: 'https://careers.greenworkx.org/apply?career=Greenworkx%20Community%20Member&pathway=qualified&utm_source=journey-app',
  },
  completeProfile: {
    heading: 'Complete your profile',
    buttonTitle: 'Go to profile',
    href: 'https://profile.greenworkx.org/',
  },
};

export const isPathwayKey = (input: unknown): input is PathwayKey =>
  PATHWAY_KEYS.includes(input as PathwayKey);

export const isPathwaySearchParam = (input: unknown): input is PathwayKeyCamelCase =>
  PATHWAY_SEARCH_PARAM_VALUES.includes(input as PathwayKeyCamelCase);

const pathwayRankingImportance: Record<PathwayKey, Record<MotivatingFactorsKey, number>> = {
  LOW_CARBON_INSTALLATION: {
    HANDS_ON: 10,
    HIGH_PAYING: 8,
    CUSTOMER_FACING: 5,
    CLIMATE: 2,
    FUEL_POVERTY: 0,
  },
  ENERGY_ADVICE: {
    FUEL_POVERTY: 10,
    CUSTOMER_FACING: 8,
    CLIMATE: 5,
    HIGH_PAYING: 2,
    HANDS_ON: 0,
  },
  ENERGY_ASSESSMENT: {
    CUSTOMER_FACING: 10,
    CLIMATE: 7,
    HIGH_PAYING: 4,
    HANDS_ON: 1,
    FUEL_POVERTY: 1,
  },
};

export const recommendPathwayFromRanking = (
  rankings: MotivatingFactorsKey[],
): { score: number; pathwayKey: PathwayKey }[] => {
  const scores = PATHWAY_KEYS.map((key) => {
    /*
    Each pathway key has a ranking importance for each of the motivation statements.
    The user will have placed each statement in a given position (from 0 to n, where n is the number of options).
    We invert the value of that position (by subtracting the position index from the length of options).

    We multiply the position value by the relative importance for the statement, and sum them, to
    get the users relevance score for that pathway.
    */
    let score = 0;
    for (const [i, r] of rankings.entries()) {
      score += pathwayRankingImportance[key][r] * (rankings.length - i);
    }
    return { score, pathwayKey: key };
  });
  return scores.sort((a, b) => b.score - a.score);
};
