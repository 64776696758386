import 'vanilla-cookieconsent/dist/cookieconsent.css';
import '../index.css';

import { RootLayout } from '@components/Pages/RootLayout';
import { CookieConsentWrapper } from '@greenworkx/cookie-consent';
import { utmTrackingSearchParams } from '@lib/tanstack';
import { createRootRoute } from '@tanstack/react-router';
import React, { Suspense } from 'react';

/*
 * NOTE: This file is always rendered regardless of route
 */

// Only render the devtools in development/preview mode
// https://tanstack.com/router/latest/docs/framework/react/devtools#only-importing-and-using-devtools-in-development
const TanStackRouterDevtools =
  import.meta.env.MODE === 'production'
    ? () => null
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

const JotaiDevTools =
  import.meta.env.MODE !== 'production'
    ? React.lazy(() =>
        import('jotai-devtools/styles.css')
          .then(() => import('jotai-devtools'))
          .then((module) => {
            return { default: module.DevTools };
          }),
      )
    : () => null;

export const Route = createRootRoute({
  validateSearch: utmTrackingSearchParams,
  component: () => (
    <>
      <RootLayout />
      <CookieConsentWrapper />
      <Suspense>
        <TanStackRouterDevtools />
        <JotaiDevTools position='bottom-right' />
      </Suspense>
    </>
  ),
});
