import classNames from 'classnames';

interface Props {
  variant: 'line' | 'paragraph' | 'heading' | 'button';
}

export function Skeleton({ variant }: Props) {
  const skeletonClasses = classNames('animate-pulse bg-neutral-300 rounded-full', {
    'h-4 mb-2 first:w-full last:w-3/4': variant === 'paragraph' || variant === 'line',
    'h-7 mb-4 w-3/4': variant === 'heading',
    'h-11 w-80': variant === 'button',
  });

  return (
    <div>
      <div className={skeletonClasses}></div>
      {variant === 'paragraph' && <div className={skeletonClasses}></div>}
    </div>
  );
}
