import { DiscoveryQuestion } from '@components/DiscoveryQuestion/DiscoveryQuestion';
import { TransitionFadeInOut } from '@components/Transitions/FadeInOut';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { isAlreadyWorkingAtom } from 'store';

export const Route = createFileRoute('/discovery')({
  component: DiscoveryPage,
});

function DiscoveryPage() {
  const [isQuestionVisible, setIsQuestionVisible] = useState(true);
  const navigate = useNavigate();
  const searchParams = Route.useSearch();
  const setIsAlreadyWorking = useSetAtom(isAlreadyWorkingAtom);

  const handleSubmit = (answerKey: string) => {
    const isAlreadyWorking = answerKey === 'ALREADY_WORKING';
    setIsAlreadyWorking(isAlreadyWorking);
    setIsQuestionVisible(false);

    void navigate({
      to: isAlreadyWorking ? '/work' : '/rank',
      search: { ...searchParams },
    });
  };

  return (
    <TransitionFadeInOut transitionKey='discovery' isVisible={isQuestionVisible}>
      <DiscoveryQuestion handleSubmit={handleSubmit} />
    </TransitionFadeInOut>
  );
}
