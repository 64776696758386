import { Button } from '@components/Button';
import { Callout } from '@components/Callout';
import { RecommendationList } from '@components/RecommendationList/RecommendationList';
import { Skeleton } from '@components/Skeleton';
import { createGwxApiClient } from '@lib/gwx_api';
import { isPathwayKey } from '@lib/recommend';
import { useContent } from '@lib/swr/useContent';
import { useLearnerApi } from '@lib/swr/useLearnerApi';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { staggerFadeIn } from 'lib/framer-motion/stagger_fade_in';
import { useEffect, useState } from 'react';
import {
  careerRecommendations,
  gwxApiIsPatchingAtom,
  journeyStageAtom,
  selectedPathwayAtom,
} from 'store';
import type { CareerPathwayContent, PathwayKey } from 'types';

export interface RecommendationWithPathwayKey {
  content?: CareerPathwayContent;
  key: PathwayKey;
}

export const Route = createFileRoute('/discover')({
  component: DiscoverPage,
});

function DiscoverPage() {
  const navigate = useNavigate();

  const [selectedPathwayLocalStorage, setSelectedPathwayLocalStorage] =
    useAtom(selectedPathwayAtom);
  const [selectedPathwayButton, setSelectedPathwayButton] = useState<PathwayKey | null>(
    selectedPathwayLocalStorage,
  );

  const setJourneyStageLocalStorage = useSetAtom(journeyStageAtom);
  const recommendations = useAtomValue(careerRecommendations);
  const setGwxApiIsPatching = useSetAtom(gwxApiIsPatchingAtom);

  const { learner, gwxApiIsLoading, invalidateLearner } = useLearnerApi();
  const { content, contentApiIsLoading } = useContent();

  const helperMessage = content?.discoverPage?.helperMessage;
  const pathwaysContent = content?.careerPathways;

  useEffect(() => {
    if (learner?.careerPathway) setSelectedPathwayButton(learner.careerPathway);
  }, [learner]);

  const [topPickPathway, ...otherPathways] = recommendations.map((rec) => {
    const pathwayContent = pathwaysContent?.find(
      (document) => document.keyCustom === rec.pathwayKey,
    );
    return {
      content: pathwayContent,
      key: rec.pathwayKey,
    };
  });

  async function updateLearner() {
    if (!selectedPathwayButton || !isPathwayKey(selectedPathwayButton)) return;

    const gwxApiClient = createGwxApiClient();
    setGwxApiIsPatching(true);
    try {
      await gwxApiClient.updateLearner(
        { careerPathway: selectedPathwayButton, journeyStage: 'LEARN' },
        { params: { id: 'self' } },
      );
    } catch {
      return;
    }
  }

  async function handleSubmit() {
    if (isPathwayKey(selectedPathwayButton)) setSelectedPathwayLocalStorage(selectedPathwayButton);
    void navigate({
      to: '/dashboard',
    });
    // Skip API call if no change
    if (selectedPathwayButton === learner?.careerPathway) {
      return;
    }
    setJourneyStageLocalStorage('LEARN');
    if (learner)
      await updateLearner().then(async () => {
        await invalidateLearner();
        setGwxApiIsPatching(false);
      });
  }

  return (
    <div
      className='h-full w-full py-6 flex flex-col gap-10 mt-4'
      aria-busy={contentApiIsLoading || gwxApiIsLoading}
      aria-hidden={contentApiIsLoading || gwxApiIsLoading}
      aria-live='polite'
    >
      {contentApiIsLoading && <Skeleton variant='paragraph' />}
      {helperMessage && (
        <Callout>
          <p className='whitespace-pre-line'>{helperMessage}</p>
        </Callout>
      )}
      {gwxApiIsLoading || contentApiIsLoading ? (
        recommendations.map((rec) => <Skeleton variant='paragraph' key={rec.pathwayKey} />)
      ) : (
        <motion.div
          className='flex flex-col gap-4 md:gap-8'
          variants={staggerFadeIn}
          animate='animate'
          initial='initial'
        >
          <RecommendationList
            recommendations={[topPickPathway]}
            hasTopPick={true}
            animation={staggerFadeIn}
            selectedPathwayButton={selectedPathwayButton}
            setSelectedPathwayButton={setSelectedPathwayButton}
            itemsAreSelectable={true}
          />

          <RecommendationList
            recommendations={otherPathways}
            animation={staggerFadeIn}
            selectedPathwayButton={selectedPathwayButton}
            setSelectedPathwayButton={setSelectedPathwayButton}
            itemsAreSelectable={true}
          />
        </motion.div>
      )}
      {gwxApiIsLoading || contentApiIsLoading ? (
        <div className='self-center'>
          <Skeleton variant='button' />
        </div>
      ) : (
        <Button isDisabled={!selectedPathwayButton} onClick={handleSubmit}>
          Submit
        </Button>
      )}
    </div>
  );
}
