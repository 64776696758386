import { Fragment } from 'react';
import type { TimelineContent } from 'types';

interface TimelineProps {
  content: TimelineContent;
}

export function Timeline({ content }: TimelineProps) {
  const { steps } = content;
  return (
    <div className='grid grid-cols-[max-content_1fr]'>
      {steps.map((step, index) => {
        return (
          <Fragment key={index}>
            <div>
              <div className='mt-2 rounded-full w-2 h-2 bg-black'></div>
              {index < steps.length - 1 && (
                <div className='w-[0.1rem] min-h-full bg-black mx-auto'></div>
              )}
            </div>
            <div>
              <div className='ml-4'>{step.title}</div>
              {step.intervalToNextStep && (
                <div className='text-neutral-500 my-4 ml-8'>{step.intervalToNextStep}</div>
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
