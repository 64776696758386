// returns the difference between a universal set (U) and a subset (A), aka A' = U - A
export function getUniqueElementDifference<T extends readonly unknown[]>(
  arr1: T,
  arr2: readonly T[number][],
): T[number][] {
  const superSet = new Set(arr1);
  const subsetA = new Set(arr2);

  const complementOfSubsetA = Array.from(superSet).filter((element) => !subsetA.has(element)); // aka all elements not in set A (A'), but in the universal set

  return complementOfSubsetA;
}
