import './index.css';

import { isDev, isProd } from '@lib/env';
import * as Sentry from '@sentry/react';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

// Import the generated route tree
import { routeTree } from './routeTree.gen';

// Create a new router instance
export const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

async function enableMocking() {
  if (!isDev || import.meta.env.VITE_DISABLE_MSW === '1') {
    return;
  }
  const { worker } = await import('@lib/msw/browser');
  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

// Inject the Speed Insights script & enable analytics
if (import.meta.env.PROD) {
  injectSpeedInsights();
}

Sentry.init({
  enabled: isProd,
  dsn: 'https://71124c2e99718e20e25bf3fba49777b2@o4504996521050112.ingest.us.sentry.io/4507707684749312',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Render the app
const rootElement = document.getElementById('root');
if (!!rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  enableMocking()
    .then(() => {
      root.render(
        <StrictMode>
          <RouterProvider router={router} />
        </StrictMode>,
      );
    })
    .catch((error: unknown) => {
      console.log('Could not start Mock Service Worker', error);
    });
}
