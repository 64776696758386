interface Props {
  size: number;
  fill: string;
}

export function GwxUserOutline({ size, fill }: Props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24'>
      <path
        fill={fill}
        d='M20 22H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5zm-8-9a6 6 0 1 1 0-12a6 6 0 0 1 0 12'
      />
    </svg>
  );
}
